// import { JSONSchemaType } from 'ajv'

export const schemaVocabulary = {
  $id: 'manakyun/schemas/vocabulary',
  definitions: {
    vocabulary: {
      type: 'object',
      properties: {
        vocab_id: { type: 'integer', minimum: 1 },
        is_tent: { type: 'boolean' },
        selected: { type: 'boolean', nullable: true },
        no_quiz: { type: 'boolean', nullable: true },
        /* created_at: {type: "string", format: "date-time", nullable: true}, */
        created_at: { type: 'string', nullable: false },
        last_quiz: { type: 'string', nullable: true },
        // promoted: {type: "string", nullable: true},
        // demote: {type: "string", nullable: true},
        status: { type: 'string' },
        head: {
          type: 'object',
          nullable: false,
          properties: {
            sent_id: { type: 'integer', minimum: 0 },
            text_raw: { type: 'string' }
          },
          required: ['sent_id', 'text_raw']
        },
        note: {
          type: 'object',
          nullable: true,
          properties: {
            sent_id: { type: 'integer', minimum: 0 },
            text_raw: { type: 'string' }
          },
          required: ['sent_id', 'text_raw']
        },
        hint: {
          type: 'object',
          nullable: true,
          properties: {
            sent_id: { type: 'integer', minimum: 0 },
            text_raw: { type: 'string' }
          },
          required: ['sent_id', 'text_raw']
        },
        audio_file: {
          type: 'object',
          nullable: true,
          properties: {
            vocab_id: { type: 'number', minimum: 1 },
            file_name: { type: 'string' }
          },
          required: ['vocab_id', 'file_name']
        },
        examples: {
          type: 'array',
          nullable: true,
          items: {
            type: 'object',
            properties: {
              sent_id: { type: 'integer', minimum: 0 },
              text_raw: { type: 'string' },
              translation_raw: { type: 'string' }
            },
            required: ['sent_id', 'text_raw', 'translation_raw']
          }
        },
        translations: {
          type: 'array',
          nullable: true,
          items: {
            type: 'object',
            properties: {
              sent_id: { type: 'integer', minimum: 0 },
              text_raw: { type: 'string' }
            },
            required: ['sent_id', 'text_raw']
          }
        },
        variants: {
          type: 'array',
          nullable: true,
          items: {
            type: 'object',
            properties: {
              sent_id: { type: 'integer', minimum: 0 },
              text_raw: { type: 'string' }
            },
            required: ['sent_id', 'text_raw']
          }
        },
        tags: {
          type: 'array',
          nullable: true,
          items: {
            type: 'object',
            properties: {
              tag_id: { type: 'integer', minimum: 1 },
              is_tent: { type: 'boolean' },
              vocabulary_count: { type: 'integer', minimum: 0, nullable: true },
              title: { type: 'string' },
              description: { type: 'string', nullable: true },
              created_at: { type: 'string', format: 'date-time', nullable: false }
            },
            required: ['tag_id', 'title', 'created_at']
          }
        },
        postags: {
          type: 'array',
          nullable: true,
          items: {
            type: 'object',
            properties: {
              id: { type: 'integer', minimum: 1 },
              title: { type: 'string' },
              description: { type: 'string', nullable: true },
              category: { type: 'string', nullable: false }
            },
            required: ['id', 'title', 'category']
          }
        },
        liebiaos: {
          type: 'array',
          nullable: true,
          items: {
            type: 'object',
            properties: {
              liebiao_id: { type: 'integer', minimum: 1 },
              is_tent: { type: 'boolean' },
              parent_id: { type: 'integer', minimum: 1, nullable: true },
              vocabulary_count: { type: 'integer', minimum: 0, nullable: false },
              title: { type: 'string', nullable: false },
              description: { type: 'string', nullable: true },
              created_at: { type: 'string', format: 'date-time', nullable: false },
              total_quizzed: { type: 'integer', minimum: 0, nullable: false },
              last_quizzed: { type: 'string', format: 'date-time', nullable: true }
            },
            required: ['liebiao_id', 'title', 'vocabulary_count', 'created_at', 'total_quizzed']
          }
        }
      },
      required: ['vocab_id']
    }
  }
}

export const schemaIndexedVocabulary = {
  $id: 'manakyun/schemas/indexedVocabulary',
  definitions: {
    indexedVocabulary: {
      type: 'object',
      properties: {
        vocab_id: { type: 'integer', minimum: 1 },
        is_tent: { type: 'boolean' },
        selected: { type: 'boolean', nullable: true },
        no_quiz: { type: 'boolean', nullable: true },
        /* created_at: {type: "string", format: "date-time", nullable: true}, */
        created_at_ts: { type: 'integer', nullable: false, minimum: 0 },
        updated_at_ts: { type: 'integer', nullable: true, minimum: 0 },
        last_quiz: { type: 'string', nullable: true },
        // promoted: {type: "string", nullable: true},
        // demote: {type: "string", nullable: true},
        status: { type: 'string' },
        head: {
          type: 'object',
          nullable: false,
          properties: {
            sent_id: { type: 'integer', minimum: 0 },
            text_raw: { type: 'string' },
            text_searchable_kanji_only: { type: 'string' },
            text_searchable_kana_only: { type: 'string' }
          },
          required: ['sent_id', 'text_searchable_kanji_only', 'text_searchable_kana_only', 'text_raw']
        },
        note: {
          type: 'object',
          nullable: true,
          properties: {
            sent_id: { type: 'integer', minimum: 0 },
            text_raw: { type: 'string' },
            text_searchable: { type: 'string' }
          },
          required: ['sent_id', 'text_raw', 'text_searchable']
        },
        hint: {
          type: 'object',
          nullable: true,
          properties: {
            sent_id: { type: 'integer', minimum: 0 },
            text_raw: { type: 'string' },
            text_searchable: { type: 'string' }
          },
          required: ['sent_id', 'text_raw', 'text_searchable']
        },
        audio_file: {
          type: 'object',
          nullable: true,
          properties: {
            vocab_id: { type: 'number', minimum: 1 },
            file_name: { type: 'string' }
          },
          required: ['vocab_id', 'file_name']
        },
        examples: {
          type: 'array',
          nullable: true,
          items: {
            type: 'object',
            properties: {
              sent_id: { type: 'integer', minimum: 0 },
              text_raw: { type: 'string' },
              text_searchable_kanji_only: { type: 'string' },
              text_searchable_kana_only: { type: 'string' },
              translation_raw: { type: 'string' },
              translation_searchable: { type: 'string' }
            },
            required: ['sent_id', 'text_raw', 'text_searchable_kanji_only', 'text_searchable_kana_only', 'translation_raw', 'translation_searchable']
          }
        },
        translations: {
          type: 'array',
          nullable: true,
          items: {
            type: 'object',
            properties: {
              sent_id: { type: 'integer', minimum: 0 },
              text_raw: { type: 'string' },
              text_searchable: { type: 'string' }
            },
            required: ['sent_id', 'text_raw', 'text_searchable']
          }
        },
        variants: {
          type: 'array',
          nullable: true,
          items: {
            type: 'object',
            properties: {
              sent_id: { type: 'integer', minimum: 0 },
              text_raw: { type: 'string' },
              text_searchable_kanji_only: { type: 'string' },
              text_searchable_kana_only: { type: 'string' }
            },
            required: ['sent_id', 'text_raw', 'text_searchable_kanji_only', 'text_searchable_kana_only']
          }
        },
        tags: {
          type: 'array',
          nullable: true,
          items: {
            type: 'integer'
          }
        },
        postags: {
          type: 'array',
          nullable: true,
          items: {
            type: 'integer'
          }
        },
        liebiaos: {
          type: 'array',
          nullable: true,
          items: {
            type: 'integer'
          }
        }
      },
      required: ['created_at_ts', 'vocab_id', 'is_tent', 'head', 'status']
    }
  }
}

export const schemaLiebiao = {
  $id: 'manakyun/schemas/liebiao',
  definitions: {
    liebiao: {
      type: 'object',
      properties: {
        liebiao_id: { type: 'integer', minimum: 1 },
        is_tent: { type: 'boolean' },
        parent_id: { type: 'integer', minimum: 1, nullable: true },
        created_at: { type: 'string', nullable: false },
        title: { type: 'string', nullable: false },
        description: { type: 'string', nullable: true },
        vocabulary_count: { type: 'integer', minimum: 0, nullable: true },
        total_quizzed: { type: 'integer', minimum: 0, nullable: true },
        last_quizzed: { type: 'string', format: 'date-time', nullable: true },
        full_path: { type: 'string', nullable: true }
      },
      required: ['liebiao_id', 'title', 'created_at']
    }
  }
}

export const schemaLiebiaoTree = {
  $id: 'manakyun/schemas/liebiaoTree',
  definitions: {
    liebiaoTree: {
      type: 'object',
      properties: {
        liebiao_id: { type: 'integer', minimum: 1 },
        is_tent: { type: 'boolean' },
        parent_id: { type: 'integer', minimum: 1, nullable: true },
        created_at: { type: 'string', nullable: false },
        title: { type: 'string', nullable: false },
        title_searchable: { type: 'string', nullable: false },
        selected: { type: 'boolean' },
        selected_buffer: { type: 'boolean' },
        descendents_selected: { type: 'integer' },
        descendents_selected_buffer: { type: 'integer' },
        actionMenu: { type: 'boolean' },
        do_quiz: { type: 'boolean' },
        full_path: { type: 'string' },
        path: { type: 'string' },
        children: {
          type: 'array',
          items: { $ref: 'liebiaoTree#/definitions/liebiaoTree' }
        }
      },
      required: ['liebiao_id', 'title', 'selected', 'selected_buffer', 'descendents_selected', 'descendents_selected_buffer', 'actionMenu', 'title_searchable', 'path', 'full_path']
    }
  }
}

export const schemaLiebiaoIndexed = {
  $id: 'manakyun/schemas/indexedLiebiao',
  definitions: {
    indexedLiebiao: {
      type: 'object',
      properties: {
        liebiao_id: { type: 'integer', minimum: 1 },
        is_tent: { type: 'boolean' },
        parent_id: { type: 'integer', minimum: 1, nullable: true },
        created_at_ts: { type: 'integer', nullable: false, minimum: 0 },
        updated_at_ts: { type: 'integer', nullable: true, minimum: 0 },
        title: { type: 'string', nullable: false },
        title_searchable: { type: 'string', nullable: false },
        description: { type: 'string', nullable: true },
        vocabulary_count: { type: 'integer', minimum: 0, nullable: true },
        total_quizzed: { type: 'integer', minimum: 0, nullable: true },
        last_quizzed: { type: 'string', format: 'date-time', nullable: true },
        full_path: { type: 'string' },
        path: { type: 'string' }
      },
      required: ['title', 'title_searchable', 'created_at_ts', 'liebiao_id', 'full_path', 'path']
    }
  }
}

export const schemaTag = {
  $id: 'manakyun/schemas/tag',
  definitions: {
    tag: {
      type: 'object',
      properties: {
        tag_id: { type: 'integer', minimum: 1 },
        is_tent: { type: 'boolean' },
        created_at: { type: 'string', format: 'date-time', nullable: false },
        title: { type: 'string', nullable: false },
        description: { type: 'string', nullable: true },
        vocabulary_count: { type: 'integer', minimum: 0, nullable: true }
      },
      required: ['title', 'created_at', 'tag_id']
    }
  }
}

export const schemaTagIndexed = {
  $id: 'manakyun/schemas/indexedTag',
  definitions: {
    indexedTag: {
      type: 'object',
      properties: {
        tag_id: { type: 'integer', minimum: 1 },
        is_tent: { type: 'boolean' },
        created_at_ts: { type: 'integer', nullable: false, minimum: 0 },
        updated_at_ts: { type: 'integer', nullable: true, minimum: 0 },
        title: { type: 'string', nullable: false },
        title_searchable: { type: 'string', nullable: false },
        description: { type: 'string', nullable: true },
        vocabulary_count: { type: 'integer', minimum: 0, nullable: true }
      },
      required: ['title', 'created_at_ts', 'tag_id', 'title_searchable']
    }
  }
}

export const schemaPosTag = {
  $id: 'manakyun/schemas/postag',
  definitions: {
    postag: {
      type: 'object',
      properties: {
        id: { type: 'integer', minimum: 1 },
        title: { type: 'string', nullable: false },
        description: { type: 'string', nullable: true },
        category: { type: 'string', nullable: false }
      },
      required: ['id', 'title', 'category']
    }
  }
}

/*
export type DictionaryWord = {
  entry_uid: number,
  ent_seq: number,
  k_ele: Array<DictionaryKanji>
  r_ele: Array<DictionaryReading>
  sense: Array<DictionarySense>
}
*/
export const schemaDictionaryWord = {
  $id: 'manakyun/schemas/dictionaryword',
  definitions: {
    dictionaryword: {
      type: 'object',
      properties: {
        entry_uid: { type: 'integer', minimum: 1 },
        ent_seq: { type: 'integer', minimum: 1 },
        k_ele: { type: 'array', nullable: true, items: { $ref: 'dictionarykanji#/definitions/dictionarykanji' } },
        r_ele: { type: 'array', nullable: true, items: { $ref: 'dictionaryreading#/definitions/dictionaryreading' } },
        sense: { type: 'array', nullable: true, items: { $ref: 'dictionarysense#/definitions/dictionarysense' } }
      },
      required: ['entry_uid', 'ent_seq', 'r_ele']
    }
  }
}

/*
export type DictionaryReading = {
  reading_uid: number,
  binraw: string,
  re_inf: Array<string>,
  re_pri: Array<string>,
  re_nokanji?: true,
  sense: Array<DictionarySense>
}
*/

export const schemaDictionaryReading = {
  $id: 'manakyun/schemas/dictionaryreading',
  definitions: {
    dictionaryreading: {
      type: 'object',
      properties: {
        reading_uid: { type: 'integer', minimum: 1 },
        binraw: { type: 'string', nullable: false },
        re_inf: { type: 'array', nullable: true, items: { type: 'string' } },
        re_pri: { type: 'array', nullable: true, items: { type: 'string' } },
        re_nokanji: { type: 'boolean' },
        sense: { type: 'array', nullable: true, items: { $ref: 'dictionarysense#/definitions/dictionarysense' } }
      },
      required: ['reading_uid', 'binraw']
    }
  }
}

/*
export type DictionaryKanji = {
  kanji_uid: number,
  binraw: string,
  ke_inf: Array<string>,
  ke_pri: Array<string>,
  r_ele: Array<DictionaryReading>,
  sense: Array<DictionarySense>
}
*/

export const schemaDictionaryKanji = {
  $id: 'manakyun/schemas/dictionarykanji',
  definitions: {
    dictionarykanji: {
      type: 'object',
      properties: {
        kanji_uid: { type: 'integer', minimum: 1 },
        binraw: { type: 'string', nullable: false },
        furigana: {
          type: 'array',
          items: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                base: { type: 'string' },
                furigana: { type: 'string', nullable: true }
              }
            }
          }
        },
        ke_inf: { type: 'array', nullable: true, items: { type: 'string' } },
        ke_pri: { type: 'array', nullable: true, items: { type: 'string' } },
        r_ele: { type: 'array', nullable: true, items: { $ref: 'dictionaryreading#/definitions/dictionaryreading' } },
        sense: { type: 'array', nullable: true, items: { $ref: 'dictionarysense#/definitions/dictionarysense' } }
      },
      required: ['kanji_uid', 'binraw']
    }
  }
}
/*
export type DictionarySense = {
  sense_uid: number,
  xref: Array<
    {
      ke_ele: DictionaryKanji,
      re_ele: DictionaryReading,
      sense: DictionarySense,
      entry_uid: number
    }
  >,
  ant: [
    {
      ke_ele: DictionaryKanji,
      re_ele: DictionaryReading,
      sense: DictionarySense,
      entry_uid: number
    },
  ],
  field: Array<string>,
  pos: Array<string>,
  misc: Array<string>,
  s_inf: Array<string>,
  dial: Array<string>,
  lsource: Array<
    {
      binraw: string,
      lang: string,
      ls_type: string,
      ls_wasei?: true
    }
  >,
  gloss: Array<
    {
      binraw: string,
      gende: string,
      lang: string,
      gloss_type: string
    }
  >
}
*/
export const schemaDictionarySense = {
  $id: 'manakyun/schemas/dictionarysense',
  definitions: {
    dictionarysense: {
      type: 'object',
      properties: {
        sense_uid: { type: 'integer', minimum: 1 },
        xref: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              ke_ele: { $ref: 'dictionarykanji#/definitions/dictionarykanji' },
              re_ele: { $ref: 'dictionaryreading#/definitions/dictionaryreading' },
              sense: { $ref: 'dictionarysense#/definitions/dictionarysense' },
              entry_uid: { type: 'integer', minimum: 1 }
            }
          }
        }
      },
      required: ['sense_uid']
    }
  }
}
