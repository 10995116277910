import { HttpService } from '@/services/http/HttpService'
import { API_LIEBIAOS_INDEX, API_TAGS_INDEX, API_POSTAGS_INDEX, API_VOCABULARY_INDEX, JDICT_FURIGANAFY, DICT_VOCABSUGGESTIONS } from '@/router/routes'
import { VocabularySuggestionsResponse, VocabularySuggestionsRequest, JPToken, FuriganafyResult, FuriganafyRequest, CJKSentenceIndexed, VocabularyIndexed, VocabularyRequestResult, Nullable, VocabularyRequestData, LiebiaoRequestData, LiebiaoRequestResult, TagRequestData, TagRequestResult, PosTagRequestResult, PosTagRequestData, VocabularyData } from '@/types'
// import Ajv /*, { JSONSchemaType } */ from 'ajv'
import { AnyValidateFunction } from 'ajv/dist/types/index'
import Ajv2019 from 'ajv/dist/2019'
import addFormats from 'ajv-formats'
import { ValidationFailed } from '@/exceptions'
import { VocabularyQueryParams } from '@/classes/VocabularyQueryParams'
import { useGlobalStore } from '@/stores/global-store'
import { storeToRefs } from 'pinia'
import type { OfflineService } from '@/services/offline/OfflineService'
import type { SettingsService } from '@/services/settings/SettingsService'
import { makeSearchableTextKanjiOnly, makeSearchableTextKanaOnly, copyObject, convertTimestamp } from '@/helpers/helpers'
import { schemaDictionarySense, schemaDictionaryKanji, schemaDictionaryReading, schemaDictionaryWord, schemaVocabulary, schemaIndexedVocabulary, schemaLiebiao, schemaTag, schemaPosTag, schemaLiebiaoTree, schemaLiebiaoIndexed } from '@/schemas'
import isEmpty from 'lodash/isEmpty'
// import { property } from 'lodash'

const vocabularyRequestResultsSchema = {
  $id: 'manakyun/schemas/vocabularyRequestResults',
  type: 'object',
  properties: {
    query: {
      type: 'object',
      nullable: true,
      properties: {
        total: { type: 'integer', minimum: 0, nullable: true },
        page: { type: 'integer', minimum: 0, nullable: true },
        taken: { type: 'integer', minimum: 0, nullable: true },
        query_id: { type: 'string', nullable: true }
      }
    },
    vocabulary_data: {
      nullable: true,
      type: 'array',
      items: { $ref: 'vocabulary#/definitions/vocabulary' }
    },
    stored: {
      nullable: true,
      type: 'array',
      items: { $ref: 'indexedVocabulary#/definitions/indexedVocabulary' }
    }
  }
}

const liebiaoRequestResultsSchema = {
  $id: 'manakyun/schemas/liebiaoRequestResults',
  type: 'object',
  properties: {
    query: {
      type: 'object',
      properties: {
        total: { type: 'integer', minimum: 0, nullable: false },
        page: { type: 'integer', minimum: 0, nullable: false },
        taken: { type: 'integer', minimum: 0, nullable: false },
        query_id: { type: 'string', nullable: true }
      },
      required: ['total', 'page', 'taken']
    },
    liebiao_data: {
      type: 'array',
      items: { $ref: 'liebiao#/definitions/liebiao' }
    },
    liebiao_tree: {
      type: 'array',
      items: { $ref: 'liebiaoTree#/definitions/liebiaoTree' }
    },
    stored: {
      type: 'array',
      items: { $ref: 'indexedLiebiao#/definitions/indexedLiebiao' }
    },
    deleted: {
      type: 'array',
      items: { type: 'integer' }
    }
  },
  required: ['query']
}

const tagRequestResultsSchema = {
  $id: 'manakyun/schemas/tagRequestResults',
  type: 'object',
  properties: {
    query: {
      type: 'object',
      nullable: true,
      properties: {
        total: { type: 'integer', minimum: 0, nullable: true },
        page: { type: 'integer', minimum: 0, nullable: true },
        taken: { type: 'integer', minimum: 0, nullable: true },
        query_id: { type: 'string', nullable: true }
      }
    },
    tag_data: {
      type: 'array',
      items: { $ref: 'tag#/definitions/tag' }
    }
  }
}

const posTagQueryResultsSchema = {
  $id: 'manakyun/schemas/posTagRequestResults',
  type: 'object',
  properties: {
    total: { type: 'integer', minimum: 0 },
    postag_data: {
      type: 'array',
      items: { $ref: 'postag#/definitions/postag' }
    }
  },
  required: ['total', 'postag_data']
}

const furiganafyResultSchema = {
  $id: 'manakyun/schemas/furiganafyResults',
  type: 'object',
  properties: {
    tokenStream: {
      type: 'object',
      properties: {
        tokens: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              base: { type: 'string', nullable: false },
              furigana: { type: 'string', nullable: true }
            },
            required: ['base']
          }
        },
        jpInput: { type: 'string' },
        rubyResult: { type: 'string' },
        kanaResult: { type: 'string' },
        category: { type: 'string', enum: ['kana', 'kanji', 'mixed', 'unknown'] }
      },
      required: ['tokens', 'jpInput', 'rubyResult', 'category']
    }
  },
  required: ['tokenStream']
}

const vocabSuggResultsSchema = {
  $id: 'manakyun/schemas/vocabSuggResults',
  type: 'object',
  properties: {
    tokenStream: {
      type: 'object',
      properties: {
        tokens: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              base: { type: 'string', nullable: false },
              furigana: { type: 'string', nullable: true }
            },
            required: ['base']
          }
        },
        jpInput: { type: 'string' },
        rubyResult: { type: 'string' },
        kanaResult: { type: 'string' },
        category: { type: 'string', enum: ['kana', 'kanji', 'mixed', 'unknown'] }
      },
      required: ['tokens', 'jpInput', 'rubyResult', 'category']
    },
    dictionary_words: {
      type: 'array',
      items: { $ref: 'dictionaryword#/definitions/dictionaryword' }
    },
    total: { type: 'integer' }
  },
  required: ['total']
}

let theAJV: Nullable<Ajv2019> = null
let vocabularyRequestResultsValidator: Nullable<AnyValidateFunction<unknown>> | undefined = null
let liebiaoRequestResultsValidator: Nullable<AnyValidateFunction<unknown>> | undefined = null
let tagRequestResultsValidator: Nullable<AnyValidateFunction<unknown>> | undefined = null
let posTagRequestResultsValidator: Nullable<AnyValidateFunction<unknown>> | undefined = null
let furiganafyResultsValidator: Nullable<AnyValidateFunction<unknown>> | undefined = null
let vocabSuggResultsValidator: Nullable<AnyValidateFunction<unknown>> | undefined = null

if (process.env.DEBUGGING) {
  theAJV = new Ajv2019({ allErrors: true, schemas: [schemaDictionarySense, schemaDictionaryKanji, schemaDictionaryReading, vocabSuggResultsSchema, schemaDictionaryWord, schemaLiebiaoIndexed, schemaLiebiaoTree, furiganafyResultSchema, vocabularyRequestResultsSchema, schemaVocabulary, schemaIndexedVocabulary, schemaPosTag, schemaLiebiao, schemaTag, liebiaoRequestResultsSchema, tagRequestResultsSchema, posTagQueryResultsSchema] })
  addFormats(theAJV)
  vocabularyRequestResultsValidator = theAJV.getSchema('manakyun/schemas/vocabularyRequestResults')
  liebiaoRequestResultsValidator = theAJV.getSchema('manakyun/schemas/liebiaoRequestResults')
  tagRequestResultsValidator = theAJV.getSchema('manakyun/schemas/tagRequestResults')
  posTagRequestResultsValidator = theAJV.getSchema('manakyun/schemas/posTagRequestResults')
  furiganafyResultsValidator = theAJV.getSchema('manakyun/schemas/furiganafyResults')
  vocabSuggResultsValidator = theAJV.getSchema('manakyun/schemas/vocabSuggResults')
}

export class VocabularyService {
  private httpService: HttpService
  private settingsService: SettingsService
  private globalStore: ReturnType<typeof useGlobalStore>
  private offlineService: Nullable<OfflineService> = null

  constructor (httpService: HttpService, settingsService:SettingsService) {
    this.httpService = httpService
    this.settingsService = settingsService
    this.globalStore = useGlobalStore()
  }

  public setOfflineService (offlineService: OfflineService): void {
    this.offlineService = offlineService
  }

  private requestVocabulary_Offline (vocabReq: VocabularyRequestData): Promise<VocabularyRequestResult> {
    console.log('getVocabularyPage_Offline')
    const { offlineState } = storeToRefs(this.globalStore)
    const vocabSyncStatus = offlineState.value.offlineServices.vocabulary.status

    // if we are already synching then we instead store locally and the new vocab will get synched in a syncUpstream cycle
    const vocabReqOps: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
      if (isEmpty(vocabReq.delete_request) && isEmpty(vocabReq.edit_vocab_data) && isEmpty(vocabReq.new_vocab_data) && isEmpty(vocabReq.state_store_request)) {
        console.log('no CRUD op requested')
        resolve(true)
        return
      }
      const vocabReqServer: VocabularyRequestData = copyObject(vocabReq) as VocabularyRequestData
      // if online, store online then locally
      if (this.globalStore.hasLocalOrInternetConnection && vocabSyncStatus === 'ready') {
        this.requestVocabulary_Server(vocabReqServer).then((reqResult: VocabularyRequestResult) => {
          if (vocabReqServer.new_vocab_data && !reqResult.stored) reject(new Error('vocab stored result returned null'))
          if (reqResult.stored) {
            this.storeIndexedVocab_Local(reqResult.stored).then(() => {
              let lastStoredTime = new Date(0)
              reqResult.stored?.forEach((vocab: VocabularyIndexed) => {
                const vocabCreateDate = new Date(convertTimestamp(vocab.created_at_ts))
                if (vocabCreateDate.getTime() > (new Date(lastStoredTime).getTime())) {
                  lastStoredTime = vocabCreateDate
                }
              })
              this.settingsService.applyOfflineSettings({ offlineServices: { vocabulary: { status: 'ready', last_synced: lastStoredTime.toISOString() } } }).then(() => {
                resolve(true)
              })
            })
          }
        })
      } else { // if offline, store locally
        const pendingOps: Array<Promise<boolean>> = []
        if (vocabReq.new_vocab_data) {
          console.log('storing locally!')
          const op = new Promise<boolean>((resolve, reject) => {
            if (!vocabReq.new_vocab_data) {
              reject('vocabReq.new_vocab_data lost!')
            } else {
              this.storeNewVocab_Local(vocabReq.new_vocab_data).then((val) => {
                resolve(val)
              }).catch(err => reject(err))
            }
          })
          pendingOps.push(op)
        }
        Promise.all(pendingOps).then(() => {
          console.log('storing locally resolved!')
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      }
    })

    return new Promise<VocabularyRequestResult>((resolve, reject) => {
      vocabReqOps.then(() => {
        if (!this.offlineService) {
          reject('Offline service not available')
        } else {
          if (!vocabReq.query_params) {
            // reject('Query params missing for local vocab query')
            resolve({})
          } else {
            this.offlineService.databaseService.getVocabulary(vocabReq.query_params).then((result) => {
              resolve(result)
            }).catch(err => {
              console.log(err)
              reject(err)
            })
          }
        }
      })
    })
  }

  private newVocabDataToIndexedVocabData (newVocabData: VocabularyData): Promise<VocabularyIndexed> {
    console.log('newVocabDataToIndexedVocabData')
    return new Promise<VocabularyIndexed>((resolve, reject) => {
      if (!this.offlineService) throw new Error('Offline service not available')
      const newHead: CJKSentenceIndexed = {
        sent_id: 0,
        text_raw: newVocabData.head,
        text_searchable_kanji_only: makeSearchableTextKanjiOnly(newVocabData.head),
        text_searchable_kana_only: makeSearchableTextKanaOnly(newVocabData.head)
      }
      this.offlineService.databaseService.generateTentIdSafe('vocabulary').then((tentVocabID: number) => {
        const nowDate = new Date()
        const indexedVocab: VocabularyIndexed = {
          vocab_id: tentVocabID,
          is_tent: true,
          created_at_ts: nowDate.getTime(),
          head: newHead,
          status: newVocabData.status,
          deleted: false
        }
        if (newVocabData.variants) {
          const newVariants: Array<CJKSentenceIndexed> = []
          newVocabData.variants.forEach((newVariant: string) => {
            newVariants.push({
              sent_id: 0,
              text_raw: newVariant,
              text_searchable_kanji_only: makeSearchableTextKanjiOnly(newVariant),
              text_searchable_kana_only: makeSearchableTextKanaOnly(newVariant)
            })
          })
          indexedVocab.variants = newVariants
        }
        if (newVocabData.audio_file) {
          indexedVocab.audio_file = {
            vocab_id: tentVocabID,
            file_name: newVocabData.audio_file.name
          }
        }
        resolve(indexedVocab)
      }).catch(err => {
        reject(err)
      })
    })
  }

  private makeVocabRequestFormData (newVocabData: VocabularyRequestData): FormData {
    const vocabFormData = new FormData()
    // if (vocabPageRequest.new_vocab_data?.audio_file) {
    //   vocabFormData.append('audio_file', vocabPageRequest.new_vocab_data?.audio_file)
    //   vocabPageRequest.new_vocab_data.audio_file = null
    // }
    if (newVocabData.new_vocab_data && newVocabData.new_vocab_data.length > 0) {
      for (let i = 0; i < newVocabData.new_vocab_data.length; i++) {
        newVocabData.new_vocab_data[i].audio_index = i
        if (newVocabData.new_vocab_data[i].audio_file) {
          const fileBlob = newVocabData.new_vocab_data[i].audio_file
          if (fileBlob) {
            vocabFormData.set(`audio_file_${i}`, fileBlob)
          }
        }
      }
    }
    vocabFormData.set('form_data', JSON.stringify(newVocabData))
    return vocabFormData
  }

  private requestVocabulary_Online (vocabPageRequest: VocabularyRequestData): Promise<VocabularyRequestResult> {
    console.log('requestVocabulary_Online')
    return this.requestVocabulary_Server(vocabPageRequest)
  }

  public requestVocabulary (vocabPageRequest: VocabularyRequestData): Promise<VocabularyRequestResult> {
    const { offlineState } = storeToRefs(this.globalStore)
    if (offlineState.value.offlineMode && offlineState.value.offlineServices.vocabulary.enabled /* && !this.globalStore.hasLocalOrInternetConnection */) {
      return this.requestVocabulary_Offline(vocabPageRequest)
    } else {
      return this.requestVocabulary_Online(vocabPageRequest)
    }
  }

  private requestVocabulary_Server (vocabReq: VocabularyRequestData): Promise<VocabularyRequestResult> {
    console.log('requestVocabulary_Server')
    const vocabFormData = this.makeVocabRequestFormData(vocabReq)
    return new Promise<VocabularyRequestResult>((resolve, reject) => {
      this.httpService.postWithRetry<FormData, VocabularyRequestResult>(API_VOCABULARY_INDEX, vocabFormData).then((response) => {
        if (process.env.DEBUGGING) {
          if (vocabularyRequestResultsValidator) {
            if (!vocabularyRequestResultsValidator(response)) {
              throw new ValidationFailed(vocabularyRequestResultsValidator.errors)
            }
          } else {
            throw new Error('vocabularyRequestResultsValidator missing!')
          }
        }
        console.log('got from server!')
        console.log(response)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  // store vocab that was created on server
  private storeIndexedVocab_Local (vocabReq: Array<VocabularyIndexed>): Promise<boolean> {
    const requestOps: Array<Promise<boolean>> = []
    vocabReq.forEach((vocab: VocabularyIndexed) => {
      if (!this.offlineService) return Promise.reject('Offline service not available')
      requestOps.push(this.offlineService.databaseService.putVocabulary(vocab))
    })
    return new Promise<boolean>((resolve, reject) => {
      Promise.all(requestOps).then(() => resolve(true)).catch((err) => reject(err))
    })
  }

  // store new vocab created on client
  private storeNewVocab_Local (vocabReq: Array<VocabularyData>): Promise<boolean> {
    const requestOps: Array<Promise<boolean>> = []
    vocabReq.forEach((vocab: VocabularyData) => {
      const op = new Promise<boolean>((resolve, reject) => {
        this.newVocabDataToIndexedVocabData(vocab).then((vocab: VocabularyIndexed) => {
          if (!this.offlineService) {
            reject('Offline service not available')
          } else {
            this.offlineService.databaseService.putVocabulary(vocab).then((val) => {
              resolve(val)
            })
          }
        })
      })
      requestOps.push(op)
    })
    return new Promise<boolean>((resolve, reject) => {
      console.log('ops complete')
      Promise.all(requestOps).then(() => resolve(true)).catch((err) => reject(err))
    })
  }

  private getVocabulary_Local (vocabQuaryParams: VocabularyQueryParams): Promise<VocabularyRequestResult> {
    return new Promise<VocabularyRequestResult>((resolve, reject) => {
      if (!this.offlineService) {
        reject('Offline service not available')
      } else if (!vocabQuaryParams) {
        reject('Query params missing for local vocab query')
      } else {
        this.offlineService.databaseService.getVocabulary(vocabQuaryParams).then((result) => {
          console.log('local vocab:')
          console.log(result)
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      }
    })
  }

  public requestLiebiaos (liebiaoReq: LiebiaoRequestData): Promise<LiebiaoRequestResult> {
    const { offlineState } = storeToRefs(this.globalStore)
    if (offlineState.value.offlineMode && offlineState.value.offlineServices.vocabulary.enabled /* && !this.globalStore.hasLocalOrInternetConnection */) {
      return this.requestLiebiaos_Offline(liebiaoReq)
    }
    return new Promise((resolve, reject) => {
      this.httpService.postWithRetry<LiebiaoRequestData, LiebiaoRequestResult>(API_LIEBIAOS_INDEX, liebiaoReq).then((response) => {
        if (process.env.DEBUGGING) {
          if (liebiaoRequestResultsValidator) {
            // console.log('validating liebiaos:')
            // console.log(response)
            if (!liebiaoRequestResultsValidator(response)) {
              throw new ValidationFailed(liebiaoRequestResultsValidator.errors)
            }
          } else {
            throw new Error('liebiaoRequestResultsValidator missing!')
          }
        }
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  private requestLiebiaos_Offline (liebiaoReq: LiebiaoRequestData): Promise<LiebiaoRequestResult> {
    if (!this.offlineService) {
      return Promise.reject('Offline service not available')
    } else {
      if (!liebiaoReq.query_params) return Promise.resolve({ query: { total: 0, page: 0, taken: 0 } })
      return this.offlineService.databaseService.getLiebiaos(liebiaoReq)
    }
  }

  public requestTags (tagReq: TagRequestData): Promise<TagRequestResult> {
    const { offlineState } = storeToRefs(this.globalStore)
    if (offlineState.value.offlineMode && offlineState.value.offlineServices.vocabulary.enabled /* && !this.globalStore.hasLocalOrInternetConnection */) {
      return this.requestTags_Offline(tagReq)
    }
    return new Promise((resolve, reject) => {
      this.httpService.postWithRetry<TagRequestData, TagRequestResult>(API_TAGS_INDEX, tagReq).then((response) => {
        if (process.env.DEBUGGING) {
          if (tagRequestResultsValidator) {
            if (!tagRequestResultsValidator(response)) {
              throw new ValidationFailed(tagRequestResultsValidator.errors)
            }
          } else {
            throw new Error('tagRequestResultsValidator missing!')
          }
        }
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  private requestTags_Offline (tagReq: TagRequestData): Promise<TagRequestResult> {
    if (!this.offlineService) {
      return Promise.reject('Offline service not available')
    } else {
      if (!tagReq.query_params) return Promise.resolve({})
      return this.offlineService.databaseService.getTags(tagReq.query_params)
    }
  }

  public requestPosTags (posTagReq: PosTagRequestData): Promise<PosTagRequestResult> {
    const { offlineState } = storeToRefs(this.globalStore)
    if (offlineState.value.offlineMode && !this.globalStore.hasLocalOrInternetConnection) {
      return this.getPosTags_Offline(posTagReq)
    }
    return new Promise((resolve, reject) => {
      this.httpService.postWithRetry<PosTagRequestData, PosTagRequestResult>(API_POSTAGS_INDEX, posTagReq).then((response) => {
        if (process.env.DEBUGGING) {
          if (posTagRequestResultsValidator) {
            if (!posTagRequestResultsValidator(response)) {
              throw new ValidationFailed(posTagRequestResultsValidator.errors)
            }
          } else {
            throw new Error('posTagRequestResultsValidator missing!')
          }
        }
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  private getPosTags_Offline (posTagReq: PosTagRequestData): Promise<PosTagRequestResult> {
    const posTagPageResult: PosTagRequestResult = {
      postag_data: []
    }
    if (posTagReq.query_params) posTagPageResult.query = copyObject(posTagReq.query_params)
    return Promise.resolve(posTagPageResult)
  }

  public getVocabularyQueyParams (): VocabularyQueryParams {
    return new VocabularyQueryParams()
  }

  /*
  tokens: Array<JPToken>
  jpInput: string
  rubyResult: string
  kanaResult?: string
  category: CJKComposition
  */
  public furiganafy (furiganaRequest: FuriganafyRequest): Promise<FuriganafyResult> {
    const { offlineState } = storeToRefs(this.globalStore)
    return new Promise<FuriganafyResult>((resolve, reject) => {
      if (furiganaRequest.furiganaType === 'empty') {
        const jpTokens: Array<JPToken> = []
        const result: FuriganafyResult = {
          tokenStream: { tokens: jpTokens, jpInput: furiganaRequest.jpText, rubyResult: '', category: 'unknown' }
        }
        return resolve(result)
      }
      if (offlineState.value.offlineMode /* && !this.globalStore.hasLocalOrInternetConnection */) {
        if (!this.offlineService) {
          reject('Offline service not available')
        } else {
          this.offlineService.parsingService.furiganafy(furiganaRequest.jpText).then((tokenStreamresult) => {
            resolve({
              tokenStream: tokenStreamresult
            })
          }).catch(err => reject(err))
        }
      } else {
        this.httpService.postWithRetry<FuriganafyRequest, FuriganafyResult>(JDICT_FURIGANAFY, { jpText: furiganaRequest.jpText, furiganaType: 'hiragana' }).then((response) => {
          if (process.env.DEBUGGING) {
            if (furiganafyResultsValidator) {
              if (!furiganafyResultsValidator(response)) {
                throw new ValidationFailed(furiganafyResultsValidator.errors)
              }
            } else {
              throw new Error('furiganafyResultsValidator missing!')
            }
          }
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  public getVocabularySuggestions (vocabSugReq: VocabularySuggestionsRequest): Promise<VocabularySuggestionsResponse> {
    const { offlineState } = storeToRefs(this.globalStore)
    return new Promise<VocabularySuggestionsResponse>((resolve, reject) => {
      if (offlineState.value.offlineMode /* && !this.globalStore.hasLocalOrInternetConnection */) {
        if (!this.offlineService) {
          reject('Offline service not available')
        } else {
          // TODO
          reject('Offline service not implemented yet')
        }
      } else {
        this.httpService.postWithRetry<VocabularySuggestionsRequest, VocabularySuggestionsResponse>(DICT_VOCABSUGGESTIONS, vocabSugReq).then((response) => {
          if (process.env.DEBUGGING) {
            if (vocabSuggResultsValidator) {
              if (!vocabSuggResultsValidator(response)) {
                throw new ValidationFailed(vocabSuggResultsValidator.errors)
              }
            } else {
              throw new Error('vocabSuggResultsValidator missing!')
            }
          }
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }
}
