import { File } from 'cordova/@awesome-cordova-plugins/file'
import { API_VOCABULARY_GET_AUDIO } from '@/router/routes'
import { Platform } from 'quasar'

export class FileService {
  public testAudio = `${API_VOCABULARY_GET_AUDIO}/1`

  public helloThere (): void {
    if (Platform.is.cordova) {
      // File.dataDirectory = file:///data/user/0/org.cordova.quasar.app/files/
      console.log(File.dataDirectory)
    } else {
      /*
      fetch(this.testAudio).then((response: Response) => {
        console.log(response)
      })
      */
    }
  }
}
