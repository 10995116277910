import { RouteRecordRaw } from 'vue-router'
import { RouteRequirement } from '@/types'
import MainPage from '@/pages/MainPage.vue'

const authRequired: RouteRequirement = {
  auth_required: true,
  role_required: 'user'
}

const tier1Required: RouteRequirement = {
  auth_required: true,
  role_required: 'user',
  subscription_required: 'tier_1'
}

/*
const adminRequired: RouteRequirement = {
  auth_required: true,
  role_required: 'admin'
}
*/

const webRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      { name: 'home', path: '', component: MainPage },
      { name: 'dictionary', path: 'dictionary', component: MainPage, props: true, meta: { route_requirement: authRequired } },
      { name: 'gettingstarted', path: 'gettingstarted', component: MainPage, props: true, meta: { route_requirement: tier1Required } },
      { name: 'signin', path: 'signin', component: MainPage },

      { name: 'search', path: 'search', component: MainPage },
      { name: 'signout', path: 'signout', component: MainPage },
      { name: 'achievements', path: 'achievements', component: MainPage, props: true, meta: { route_requirement: tier1Required } },
      { name: 'account', path: 'account', component: MainPage, props: true, meta: { route_requirement: authRequired } },
      { name: 'help', path: 'help', component: MainPage },
      { name: 'contact', path: 'contact', component: MainPage },
      { name: 'debug', path: 'debug', component: MainPage },
      {
        path: 'lessons',
        children: [
          { name: 'lessons.current', path: 'current', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.beginnerI', path: 'beginnerI', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.beginnerII', path: 'beginnerII', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.intermediateI', path: 'intermediateI', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.intermediateII', path: 'intermediateII', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.advancedI', path: 'advancedI', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.advancedII', path: 'advancedII', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.progress', path: 'progress', component: MainPage, props: true, meta: { route_requirement: authRequired } }
        ]
      },
      {
        path: 'vocabulary',
        children: [
          {
            name: 'vocabulary',
            path: '',
            component: MainPage,
            props: true,
            meta: { route_requirement: authRequired }
          },
          { name: 'vocabulary.lists', path: 'lists', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'vocabulary.tags', path: 'tags', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'vocabulary.quiz', path: 'quiz', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'vocabulary.summary', path: 'summary', component: MainPage, props: true, meta: { route_requirement: authRequired } }
        ]
      },
      {
        path: 'practice',
        children: [
          { name: 'practice.kanji', path: 'kanji', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'practice.katakana', path: 'katakana', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'practice.hiragana', path: 'hiragana', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'practice.pronunciation', path: 'pronunciation', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          {
            path: 'jlpt',
            children: [
              { name: 'practice.jlpt.n1', path: 'n1', component: MainPage, props: true, meta: { route_requirement: authRequired } },
              { name: 'practice.jlpt.n2', path: 'n2', component: MainPage, props: true, meta: { route_requirement: authRequired } },
              { name: 'practice.jlpt.n3', path: 'n3', component: MainPage, props: true, meta: { route_requirement: authRequired } },
              { name: 'practice.jlpt.n4', path: 'n4', component: MainPage, props: true, meta: { route_requirement: authRequired } },
              { name: 'practice.jlpt.n5', path: 'n5', component: MainPage, props: true, meta: { route_requirement: authRequired } }
            ]
          }
        ]
      },
      {
        path: 'reference',
        children: [
          { name: 'reference.phrasebook', path: 'phrasebook', component: MainPage },
          { name: 'reference.grammar', path: 'grammar', component: MainPage },
          { name: 'reference.idioms', path: 'idioms', component: MainPage },
          { name: 'reference.numbers', path: 'numbers', component: MainPage },
          { name: 'reference.datetime', path: 'datetime', component: MainPage },
          { name: 'reference.calendar', path: 'calendar', component: MainPage }
        ]
      },
      {
        path: 'settings',
        children: [
          { name: 'settings.appearance', path: 'appearance', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'settings.offline', path: 'offline', component: MainPage, props: true, meta: { route_requirement: tier1Required } },
          { name: 'settings.search', path: 'search', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'settings.notifications', path: 'notifications', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'settings.progress', path: 'progress', component: MainPage, props: true, meta: { route_requirement: authRequired } }
        ]
      }
    ]
  },
  // Always leave this as last one, but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/error/ErrorNotFoundPage.vue')
  }
]

/* const mobileRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      { name: 'home', path: '', component: MainPage },
      { name: 'dictionary', path: 'dictionary', component: MainPage, props: true, meta: { route_requirement: authRequired } },
      { name: 'gettingstarted', path: 'gettingstarted', component: MainPage, props: true, meta: { route_requirement: tier1Required } },
      { name: 'signin', path: 'signin', component: MainPage },

      { name: 'search', path: 'search', component: MainPage },
      { name: 'signout', path: 'signout', component: MainPage },
      { name: 'achievements', path: 'achievements', component: MainPage, props: true, meta: { route_requirement: tier1Required } },
      { name: 'account', path: 'account', component: MainPage, props: true, meta: { route_requirement: authRequired } },
      { name: 'help', path: 'help', component: MainPage },
      { name: 'contact', path: 'contact', component: MainPage },
      { name: 'debug', path: 'debug', component: MainPage },
      {
        path: 'lessons',
        children: [
          { name: 'lessons.current', path: 'current', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.beginnerI', path: 'beginnerI', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.beginnerII', path: 'beginnerII', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.intermediateI', path: 'intermediateI', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.intermediateII', path: 'intermediateII', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.advancedI', path: 'advancedI', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.advancedII', path: 'advancedII', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'lessons.progress', path: 'progress', component: MainPage, props: true, meta: { route_requirement: authRequired } }
        ]
      },
      {
        path: 'vocabulary',
        children: [
          { name: 'vocabulary', path: '', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'vocabulary.new', path: 'new', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'vocabulary.edit', path: 'edit/:id', component: MainPage, props: true, meta: { route_requirement: tier1Required } },
          { name: 'vocabulary.lists', path: 'lists', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'vocabulary.tags', path: 'tags', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'vocabulary.quiz', path: 'quiz', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'vocabulary.summary', path: 'summary', component: MainPage, props: true, meta: { route_requirement: authRequired } }
        ]
      },
      {
        path: 'practice',
        children: [
          { name: 'practice.kanji', path: 'kanji', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'practice.katakana', path: 'katakana', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'practice.hiragana', path: 'hiragana', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'practice.pronunciation', path: 'pronunciation', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          {
            path: 'jlpt',
            children: [
              { name: 'practice.jlpt.n1', path: 'n1', component: MainPage, props: true, meta: { route_requirement: authRequired } },
              { name: 'practice.jlpt.n2', path: 'n2', component: MainPage, props: true, meta: { route_requirement: authRequired } },
              { name: 'practice.jlpt.n3', path: 'n3', component: MainPage, props: true, meta: { route_requirement: authRequired } },
              { name: 'practice.jlpt.n4', path: 'n4', component: MainPage, props: true, meta: { route_requirement: authRequired } },
              { name: 'practice.jlpt.n5', path: 'n5', component: MainPage, props: true, meta: { route_requirement: authRequired } }
            ]
          }
        ]
      },
      {
        path: 'reference',
        children: [
          { name: 'reference.phrasebook', path: 'phrasebook', component: MainPage },
          { name: 'reference.grammar', path: 'grammar', component: MainPage },
          { name: 'reference.idioms', path: 'idioms', component: MainPage },
          { name: 'reference.numbers', path: 'numbers', component: MainPage },
          { name: 'reference.datetime', path: 'datetime', component: MainPage },
          { name: 'reference.calendar', path: 'calendar', component: MainPage }
        ]
      },
      {
        path: 'settings',
        children: [
          { name: 'settings.appearance', path: 'appearance', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'settings.offline', path: 'offline', component: MainPage, props: true, meta: { route_requirement: tier1Required } },
          { name: 'settings.search', path: 'search', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'settings.notifications', path: 'notifications', component: MainPage, props: true, meta: { route_requirement: authRequired } },
          { name: 'settings.progress', path: 'progress', component: MainPage, props: true, meta: { route_requirement: authRequired } }
        ]
      }
    ]
  },
  // Always leave this as last one, but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/error/ErrorNotFoundPage.vue')
  }
] */

// api routes
export const API_LOGIN_ROUTE = 'user/login'
export const API_LOGIN_ROUTE_MOBILE = 'user/sanctum/token'
export const API_LOGOUT_ROUTE = 'user/logout'
export const API_GET_SANCTUM_TOKEN = 'sanctum/csrf-cookie'
export const API_GET_USER = 'api/session-info'
export const API_KUROMOJI = 'api/kuromoji'
export const API_VOCABULARY_INDEX = 'api/vocabulary/index'
export const API_VOCABULARY_GET_AUDIO = 'api/vocabulary/getaudio'
export const API_VOCABULARY_GET_AUDIOS = 'api/vocabulary/getaudios'
// export const API_VOCABULARY_STORE = 'api/vocabulary/store'
export const API_LIEBIAOS_INDEX = 'api/liebiaos/index'
export const API_TAGS_INDEX = 'api/tags/index'
export const API_POSTAGS_INDEX = 'api/postags/index'
export const API_SETTINGS_STORE = 'api/settings/update'
export const API_SETTINGS_INDEX = 'api/settings/index'
export const API_OFFLINE_FILESTATUS = 'api/offline/filestatus'
export const API_SYNC_VOCABULARY = 'api/vocabulary/sync'
// jdict API (doesnt require auth)
export const JDICT_KUROMOJI_DATA = 'jdict/getkuromojidata'
export const JDICT_FURIGANAFY = 'jdict/furiganafy'
// dict
export const DICT_VOCABSUGGESTIONS = 'api/dict/vocabsuggestions'

export { webRoutes }
