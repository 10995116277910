import { useGlobalStore } from '@/stores/global-store'
import { HttpService } from '@/services/http/HttpService'
import { StorageService } from '@/services/storage/StorageService'
import { Nullable, UserSettings, UserSettingsData, MkFontFamily, MkFontSize, RubyMode, Optionable, RecursivePartial, OfflineState } from '@/types'
import { STORAGE_KEYS } from '@/enums'
import { API_SETTINGS_STORE, API_SETTINGS_INDEX } from '@/router/routes'
import { Dark, Notify } from 'quasar'
import { storeToRefs } from 'pinia'
import mkFonts /*, { MkFont } */ from '@/css/fonts/MkFonts'
import { userSettingsDefaults, offlineStateDefaults } from '@/services/settings/defaults'
import { Ref } from 'vue'
import { gracefulLoad, RxRef } from '@/helpers/helpers'
import { Subscription, switchMap, catchError, skip, of, map } from 'rxjs'
import { i18n } from '@/boot/i18n'
import { TOAST_TIMEOUT } from '@/services/settings/applicationSettings'
import Ajv, { JSONSchemaType } from 'ajv'
import addFormats from 'ajv-formats'
import { ValidationFailed } from '@/exceptions'
import merge from 'lodash/merge'

/*
  Steps to add a new setting:
  1) Update SettingsService.makeUserSettings
  2) Update SettingsService.makeUserSettingsData
  3) Update SettingsService.doApplySettings
  4) Update SettingsService.updateSettingsStore
  5) Add default value for setting in @/services/settings/defaults
  6) Update AuthService.userInfoDataSchema
  7) Update global-store
  8) Update server side
  9) Look at AppearanceSettings.vue for example on how to implement settings
*/

interface UserSettingsRequest {
  lastSettingsUpdate?: string
}

const userSettingsDataSchema: JSONSchemaType<UserSettingsData> = {
  type: 'object',
  properties: {
    darkMode: { type: 'integer', minimum: 0, maximum: 1, nullable: true },
    jpFontSize: { type: 'string', nullable: true },
    jpFontFamily: { type: 'string', nullable: true },
    rubyMode: { type: 'string', nullable: true },
    syncMode: { type: 'integer', nullable: true },
    lastSettingsUpdate: { type: 'string', nullable: true },
    newVocabAlwaysShowAll: { type: 'boolean', nullable: true }
  }
}

const ajv = new Ajv()
addFormats(ajv)
const userSettingsValidator = ajv.compile<UserSettingsData>(userSettingsDataSchema)

export class SettingsService {
  private httpService: HttpService
  private globalStore: ReturnType<typeof useGlobalStore>
  private pendingNotif: ReturnType<typeof Notify.create> | null = null
  private failedNotif: ReturnType<typeof Notify.create> | null = null
  private completedNotif: ReturnType<typeof Notify.create> | null = null
  private storageService: StorageService
  private isSyncing = false

  constructor (httpService: HttpService, storageService: StorageService) {
    this.storageService = storageService
    this.httpService = httpService
    this.globalStore = useGlobalStore()
  }

  public isOptionable (ding: unknown): ding is Optionable {
    return (<Optionable>ding).label !== undefined && (<Optionable>ding).value !== undefined
  }

  public optionableSetting<T> (settingRxref: RxRef<T>, setting: keyof UserSettings, loader: Nullable<Ref<boolean>> = null, syncSetting = true): Subscription {
    return settingRxref.pipe(
      map((val: T) => {
        if (this.isOptionable(val)) return val.value
        else return val
      }),
      switchMap((val: unknown) => {
        const userSettingFragment = { [setting]: val } as UserSettings
        if (!(userSettingFragment)) {
          throw new Error('SettingsService.asyncSetting invalid setting provided')
        }
        return gracefulLoad(this.applyUserSettings(userSettingFragment, syncSetting), 1000, 700).pipe(skip(1),
          catchError(() => {
            if (loader) loader.value = false
            Notify.create({
              timeout: TOAST_TIMEOUT,
              type: 'negative',
              message: i18n.global.t('settings.error.failed')
            })
            return of(false)
          }))
      })).subscribe({
      next: (val: boolean) => {
        if (loader) loader.value = val
      }
    })
  }

  public syncUserSettingsIfNeeded (): Promise<boolean> {
    if (this.isSyncing) return Promise.resolve(false)
    if (!this.canSyncUserSettings()) return Promise.resolve(false)
    this.isSyncing = true
    return new Promise<boolean>((resolve, reject) => {
      this.getUserSettingsFromServer().then((userSettingsData:UserSettingsData) => {
        // no settings returned
        if (Object.keys(userSettingsData).length === 0) return
        // if (Object.keys(userSettingsData).length === 1 && userSettingsData.syncMode !== undefined) {}
        this.applyUserSettingsFromServer(this.makeUserSettings(userSettingsData)).then(() => resolve(true)).catch((err) => reject(err))
      }).catch((err) => {
        console.log(err)
        reject(err)
      }).finally(() => {
        this.isSyncing = false
      })
    })
  }

  public getUserSettingsFromServer (onlyIfUpdated = true): Promise<UserSettingsData> {
    const { userSettings } = storeToRefs(this.globalStore)
    const settingsRequest: UserSettingsRequest = {}
    if (userSettings.value.lastSettingsUpdate && onlyIfUpdated) {
      settingsRequest.lastSettingsUpdate = userSettings.value.lastSettingsUpdate
    }

    return new Promise<UserSettingsData>((resolve, reject) => {
      this.httpService.postWithRetry<UserSettingsRequest, UserSettingsData>(API_SETTINGS_INDEX, settingsRequest).then((userSettingsData: UserSettingsData) => {
        if (process.env.DEBUGGING) {
          if (!userSettingsValidator(userSettingsData)) {
            throw new ValidationFailed(userSettingsValidator.errors)
          }
        }
        resolve(userSettingsData)
      }).catch((err) => {
        console.log(err)
        reject(err)
      })
    })
  }

  // settings changed within the app
  public applyUserSettings (settings: UserSettings, synchSettings = true): Promise<boolean> {
    const { offlineState, userSettings } = storeToRefs(this.globalStore)
    if (Object.keys(settings).length <= 0) {
      return Promise.resolve(true)
    }

    if (synchSettings) {
      settings.lastSettingsUpdate = (new Date()).toISOString()
      userSettings.value.lastSettingsUpdate = settings.lastSettingsUpdate
    }

    this.updateUserSettingsStore(settings)
    this.doApplyUserSettings()

    if (!synchSettings) {
      return Promise.resolve(this.saveUserSettingsToStorage(settings))
    }

    if (offlineState.value.offlineMode && !this.globalStore.hasLocalOrInternetConnection) {
      return Promise.resolve(this.saveUserSettingsToStorage(settings))
    }

    // if syncMode setting is not being changed, and syncMode is off
    if (settings.syncMode === undefined && !userSettings.value.syncMode) {
      return Promise.resolve(this.saveUserSettingsToStorage(settings))
    }

    // when syncMode is disabled, send result to server then save on local
    // when syncMode is enabled, need to send all saved settings to server
    if (settings.syncMode !== undefined) {
      if (settings.syncMode) { // syncMode enabled
        this.saveUserSettingsToStorage(settings)
        const storedSettingsData = this.getUserSettingsFromStorage()
        if (storedSettingsData) {
          settings = this.makeUserSettings(storedSettingsData)
        }
        return this.storeRemoteUserSettings(settings)
      } else { // syncMode disabled
        this.saveUserSettingsToStorage(settings) // save local first
        return this.storeRemoteUserSettings({ syncMode: false, lastSettingsUpdate: settings.lastSettingsUpdate })
      }
    }

    // TODO, when we go back online, settings need to be saved to server
    return this.storeRemoteUserSettings(settings).then(() => this.saveUserSettingsToStorage(settings))
  }

  // settings loaded from server after user logs in, etc.
  public applyUserSettingsFromServer (settings: UserSettings): Promise<boolean> {
    const { userSettings } = storeToRefs(this.globalStore)
    let isSyncing = false
    let showSyncMsg = false
    if (settings.lastSettingsUpdate && userSettings.value.lastSettingsUpdate !== '') {
      isSyncing = (new Date(settings.lastSettingsUpdate) > new Date(userSettings.value.lastSettingsUpdate))
      showSyncMsg = true
    }

    if (Object.keys(settings).length <= 0) return Promise.resolve(true)

    if (settings.syncMode !== undefined && !settings.syncMode) {
      settings = { syncMode: false, lastSettingsUpdate: settings.lastSettingsUpdate }
      showSyncMsg = false // dont show syncMsg when simply disabling syncMode
    }

    // when syncing settings, server is authoratative. hence if if syncMode was enabled and some settings were not returned (they were not set and saved from another device),
    // then need to restore defaults to complete the sync
    if (settings.syncMode !== undefined && settings.syncMode) {
      settings = { ...userSettingsDefaults, ...settings }
    }

    const settingsAppliedResult = this.updateUserSettingsStore(settings)
    this.doApplyUserSettings()
    this.saveUserSettingsToStorage(settings)
    return new Promise<boolean>((resolve, reject) => {
      settingsAppliedResult.then((results: PromiseSettledResult<boolean>[]) => {
        let promiseResult = true
        results.forEach((result: PromiseSettledResult<boolean>) => {
          if (result.status === 'rejected') promiseResult = false
        })
        if (promiseResult) {
          if (isSyncing && showSyncMsg) {
            setTimeout(() => { // add small delay, give browser time to re-render
              Notify.create({
                timeout: TOAST_TIMEOUT,
                type: 'positive',
                message: i18n.global.t('settings.sync.complete')
              })
            }, 200)
          }
          return resolve(true)
        } else {
          return reject(false)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  // settings loaded from local storage
  public applyUserSettingsFromStorage (): boolean {
    const storedSettingsData = this.getUserSettingsFromStorage()
    /*
    let settingsAppliedResult: Promise<PromiseSettledResult<boolean>[]> = Promise.resolve([{ status: 'rejected', reason: 'No settings found in local storage' }])
    if (storedSettingsData) {
      settingsAppliedResult = this.updateUserSettingsStore(this.makeUserSettings(storedSettingsData))
    }
    */
    if (storedSettingsData) this.updateUserSettingsStore(this.makeUserSettings(storedSettingsData))
    this.doApplyUserSettings()
    return true
  }

  private loadFont (mkFontFamily: MkFontFamily): Promise<boolean> {
    document.fonts.onloadingdone = () => {
      //
    }
    return new Promise<boolean>((resolve, reject) => {
      for (const dafont of document.fonts.values()) {
        if (dafont.family === mkFontFamily) {
        // console.log(`found ${japFont.value.value}!`)
          return resolve(true)
        }
      }

      const mkFont = mkFonts.get(mkFontFamily)
      if (mkFont?.url) {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = mkFont.url
        document.fonts.onloadingdone = () => {
          resolve(true)
        }
        document.head.appendChild(link)
      } else {
        reject(`${mkFontFamily} is missing url`)
      }
    })
  }

  private updateUserSettingsStore (settings: UserSettings): Promise<PromiseSettledResult<boolean>[]> {
    const { userSettings } = storeToRefs(this.globalStore)
    const settingsResults: Promise<boolean>[] = []
    if (settings.darkMode !== undefined) {
      userSettings.value.darkMode = settings.darkMode
      settingsResults.push(Promise.resolve(true))
    }
    if (settings.jpFontFamily !== undefined) {
      userSettings.value.jpFontFamily = settings.jpFontFamily
      settingsResults.push(this.loadFont(userSettings.value.jpFontFamily))
    }
    if (settings.jpFontSize !== undefined) {
      userSettings.value.jpFontSize = settings.jpFontSize
      settingsResults.push(Promise.resolve(true))
    }
    if (settings.rubyMode !== undefined) {
      userSettings.value.rubyMode = settings.rubyMode
      settingsResults.push(Promise.resolve(true))
    }
    if (settings.syncMode !== undefined) {
      userSettings.value.syncMode = settings.syncMode
      settingsResults.push(Promise.resolve(true))
    }
    if (settings.lastSettingsUpdate !== undefined) {
      userSettings.value.lastSettingsUpdate = settings.lastSettingsUpdate
      settingsResults.push(Promise.resolve(true))
    }
    if (settings.newVocabAlwaysShowAll !== undefined) {
      userSettings.value.newVocabAlwaysShowAll = settings.newVocabAlwaysShowAll
      settingsResults.push(Promise.resolve(true))
    }
    return Promise.allSettled(settingsResults)
  }

  // for setting changes that have side effects
  private doApplyUserSettings (): boolean {
    const { userSettings } = storeToRefs(this.globalStore)
    Dark.set(userSettings.value.darkMode)
    return true
  }

  private getUserSettingsFromStorage (): UserSettingsData | null {
    const settingsResult = this.storageService.getValue(STORAGE_KEYS.USER_SETTINGS)
    if (settingsResult) {
      return JSON.parse(settingsResult) as UserSettingsData
    } else {
      return null
    }
  }

  private saveUserSettingsToStorage (userSettings: UserSettings): boolean {
    const storedSettingsData = this.getUserSettingsFromStorage()
    if (storedSettingsData) {
      const storedSettings = this.makeUserSettings(storedSettingsData)
      userSettings = { ...storedSettings, ...userSettings }
    }
    try {
      this.storageService.storeValue(STORAGE_KEYS.USER_SETTINGS, JSON.stringify(this.makeUserSettingsData(userSettings)))
    } catch (error) {
      console.log(error)
      return false
    }
    return true
  }

  private storeRemoteUserSettings (settings: UserSettings): Promise<boolean> {
    const { appState } = storeToRefs(this.globalStore)
    if (!appState.value.userIsAuthed || !this.globalStore.hasLocalOrInternetConnection) {
      return Promise.reject(false)
    }
    return new Promise<boolean>((resolve, reject) => {
      const userSettingsData: UserSettingsData = this.makeUserSettingsData(settings)
      this.httpService.postWithRetry<UserSettingsData, boolean>(API_SETTINGS_STORE, userSettingsData).then(() => {
        // need to update settings
        resolve(true)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  public pushLocalUserSettingsToServer (): Promise<boolean> {
    const { userSettings } = storeToRefs(this.globalStore)
    if (!userSettings.value.syncMode) return Promise.resolve(false)
    const storedUserSettings = this.getUserSettingsFromStorage()
    if (storedUserSettings) {
      return this.storeRemoteUserSettings(this.makeUserSettings(storedUserSettings))
    }
    return Promise.resolve(false)
  }

  public makeUserSettingsData (userSettings: UserSettings): UserSettingsData {
    const userSettingsData: UserSettingsData = {}
    if (userSettings.darkMode !== undefined) userSettingsData.darkMode = userSettings.darkMode ? 1 : 0
    if (userSettings.jpFontSize !== undefined) userSettingsData.jpFontSize = userSettings.jpFontSize
    if (userSettings.jpFontFamily !== undefined) userSettingsData.jpFontFamily = userSettings.jpFontFamily
    if (userSettings.rubyMode !== undefined) userSettingsData.rubyMode = userSettings.rubyMode
    if (userSettings.syncMode !== undefined) userSettingsData.syncMode = userSettings.syncMode ? 1 : 0
    if (userSettings.lastSettingsUpdate !== undefined) userSettingsData.lastSettingsUpdate = userSettings.lastSettingsUpdate
    if (userSettings.newVocabAlwaysShowAll !== undefined) userSettingsData.newVocabAlwaysShowAll = userSettings.newVocabAlwaysShowAll
    return userSettingsData
  }

  public makeUserSettings (settingsData: UserSettingsData): UserSettings {
    const userSettings: UserSettings = {}
    if (settingsData) {
      if (settingsData.darkMode !== undefined) userSettings.darkMode = Boolean(settingsData.darkMode)
      if (settingsData.jpFontFamily !== undefined) userSettings.jpFontFamily = settingsData.jpFontFamily as MkFontFamily
      if (settingsData.jpFontSize !== undefined) userSettings.jpFontSize = settingsData.jpFontSize as MkFontSize
      if (settingsData.rubyMode !== undefined) userSettings.rubyMode = settingsData.rubyMode as RubyMode
      if (settingsData.syncMode !== undefined) userSettings.syncMode = Boolean(settingsData.syncMode)
      if (settingsData.lastSettingsUpdate !== undefined) userSettings.lastSettingsUpdate = settingsData.lastSettingsUpdate
      if (settingsData.newVocabAlwaysShowAll !== undefined) userSettings.newVocabAlwaysShowAll = settingsData.newVocabAlwaysShowAll
    }
    return userSettings
  }

  public canSyncUserSettings (): boolean {
    const { appState, userSettings } = storeToRefs(this.globalStore)
    return appState.value.userIsAuthed && userSettings.value.syncMode && this.globalStore.hasLocalOrInternetConnection
  }

  /**
   * Offline settings
   *
   */

  public removeOfflineSettings (): boolean {
    const { offlineState } = storeToRefs(this.globalStore)
    offlineState.value.offlineMode = offlineStateDefaults.offlineMode
    offlineState.value.offlineServices = offlineStateDefaults.offlineServices
    this.storageService.deleteValue(STORAGE_KEYS.OFFLINE_STATE)
    return true
  }

  public applyOfflineSettings (daOfflineState: RecursivePartial<OfflineState>): Promise<boolean> {
    if (!this.saveOfflineSettingsToStorage(daOfflineState)) {
      return Promise.resolve(false)
    }
    return new Promise<boolean>((resolve, reject) => {
      this.updateOfflineStateStore(daOfflineState).then((results: PromiseSettledResult<boolean>[]) => {
        let promiseResult = true
        results.forEach((result: PromiseSettledResult<boolean>) => {
          if (result.status === 'rejected') promiseResult = false
        })
        if (promiseResult) {
          return resolve(true)
        } else {
          return reject(false)
        }
      }).catch((err) => {
        console.log('applyOfflineSettings: could not update store')
        console.log(err)
        reject(err)
      })
    })
  }

  private updateOfflineStateStore (daOfflineState: RecursivePartial<OfflineState>): Promise<PromiseSettledResult<boolean>[]> {
    const { offlineState } = storeToRefs(this.globalStore)
    const updateResult: Promise<boolean>[] = []

    if (daOfflineState.offlineMode !== undefined) {
      offlineState.value.offlineMode = daOfflineState.offlineMode
      updateResult.push(Promise.resolve(true))
    }

    if (daOfflineState.offlineServices) {
      if (daOfflineState.offlineServices.dictionary !== undefined) {
        if (daOfflineState.offlineServices.dictionary.enabled !== undefined) {
          offlineState.value.offlineServices.dictionary.enabled = daOfflineState.offlineServices.dictionary.enabled
        }
        if (daOfflineState.offlineServices.dictionary.status !== undefined) {
          offlineState.value.offlineServices.dictionary.status = daOfflineState.offlineServices.dictionary.status
        }
        updateResult.push(Promise.resolve(true))
      }
      if (daOfflineState.offlineServices.vocabulary !== undefined) {
        if (daOfflineState.offlineServices.vocabulary.enabled !== undefined) {
          offlineState.value.offlineServices.vocabulary.enabled = daOfflineState.offlineServices.vocabulary.enabled
        }
        if (daOfflineState.offlineServices.vocabulary.status !== undefined) {
          offlineState.value.offlineServices.vocabulary.status = daOfflineState.offlineServices.vocabulary.status
        }
        if (daOfflineState.offlineServices.vocabulary.last_synced !== undefined) {
          offlineState.value.offlineServices.vocabulary.last_synced = daOfflineState.offlineServices.vocabulary.last_synced
        }

        updateResult.push(Promise.resolve(true))
      }
    }

    return Promise.allSettled(updateResult)
  }

  public applyOfflineStateFromStorage (): boolean {
    const daOfflineState = this.getOfflineStateFromStorage()
    /*
    if (daOfflineState) {
      return new Promise<boolean>((resolve, reject) => {
        this.updateOfflineStateStore(daOfflineState).then(() => {
          resolve(true)
        }).catch((err) => {
          console.error('loadAppStateFromLocalStorage: could not update store')
          console.log(err)
          reject(err)
        })
      })
    }
    */
    if (daOfflineState) this.updateOfflineStateStore(daOfflineState)
    return true
  }

  private getOfflineStateFromStorage (): Nullable<OfflineState> {
    const settingsResult = this.storageService.getValue(STORAGE_KEYS.OFFLINE_STATE)
    if (settingsResult) {
      return JSON.parse(settingsResult) as OfflineState
    } else {
      return null
    }
  }

  private saveOfflineSettingsToStorage (offlineState: RecursivePartial<OfflineState>): boolean {
    const storedOfflineState = this.getOfflineStateFromStorage()
    // console.log(storedOfflineState)
    // console.log(offlineState)
    if (storedOfflineState) {
      // mergedSettings = { ...storedOfflineState, ...offlineState }
      offlineState = merge(storedOfflineState, offlineState)
    }
    // console.log(offlineState)

    try {
      this.storageService.storeValue(STORAGE_KEYS.OFFLINE_STATE, JSON.stringify(offlineState))
    } catch (error) {
      console.log(error)
      return false
    }
    return true
  }
}
