import { boot } from 'quasar/wrappers'
import { Platform, Screen } from 'quasar'
import { HTTP_SERVICE_KEY, AUTH_SERVICE_KEY, VOCABULARY_SERVICE_KEY, SETTINGS_SERVICE_KEY, STORAGE_SERVICE_KEY, FILE_SERVICE_KEY, AUDIO_SERVICE_KEY, PLATFORM_CORDOVA, PLATFORM_MOBILE_BROWSER, PLATFORM_APP_MODE, PLATFORM_DESKTOP } from '@/services/keys'
import { HttpService } from '@/services/http/HttpService'
import { AuthService } from '@/services/auth/AuthService'
import { VocabularyService } from '@/services/vocabulary/VocabularyService'
import { SettingsService } from '@/services/settings/SettingsService'
import { StorageService } from '@/services/storage/StorageService'
import { FileService } from '@/services/filesys/FileService'
import { AudioService } from '@/services/audio/AudioService'

const storageService = new StorageService()
const httpService = new HttpService(storageService)
const settingsService = new SettingsService(httpService, storageService)
const authService = new AuthService(httpService, settingsService, storageService)
const vocabularyService = new VocabularyService(httpService, settingsService)
const fileService = new FileService()
const audioService = new AudioService()
let platformCordova = Platform.is.cordova || Boolean(process.env.DEBUG_MODE_CORDOVA)
let platformMobileBrowser = (Platform.is.mobile && !Platform.is.cordova) || Boolean(process.env.DEBUG_MODE_MOBILE_BROWSER)
let platformDesktop = Platform.is.desktop && !platformCordova && !platformMobileBrowser
if (platformCordova === undefined) platformCordova = false
if (platformMobileBrowser === undefined) platformMobileBrowser = false
if (platformDesktop === undefined) platformDesktop = false
// const appMode = Screen.xs && (platformCordova || platformMobileBrowser)
const appMode = Screen.xs

window.mkBaseURL = process.env.API
window.mkCache = process.env.MKCACHE

export default boot(async ({ app /*, router */ }) => {
  app.provide(HTTP_SERVICE_KEY, httpService)
  app.provide(AUTH_SERVICE_KEY, authService)
  app.provide(VOCABULARY_SERVICE_KEY, vocabularyService)
  app.provide(SETTINGS_SERVICE_KEY, settingsService)
  app.provide(STORAGE_SERVICE_KEY, storageService)
  app.provide(FILE_SERVICE_KEY, fileService)
  app.provide(AUDIO_SERVICE_KEY, audioService)
  app.provide(PLATFORM_CORDOVA, platformCordova)
  app.provide(PLATFORM_MOBILE_BROWSER, platformMobileBrowser)
  app.provide(PLATFORM_DESKTOP, platformDesktop)
  app.provide(PLATFORM_APP_MODE, appMode)
})

export { storageService, httpService, settingsService, authService, vocabularyService, audioService, platformCordova, platformMobileBrowser, platformDesktop }
