import { MkFontFamily } from '@/types'

export type MkFont = {
  label: string
  value: MkFontFamily
  url?: string
  cssClass: string
}

const mkFonts: Map<MkFontFamily, MkFont> = new Map<MkFontFamily, MkFont>()
mkFonts.set('Roboto', {
  label: 'Roboto',
  value: 'Roboto',
  cssClass: 'font-roboto'
})
mkFonts.set('Noto Sans JP', {
  label: 'Noto Sans',
  value: 'Noto Sans JP',
  url: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap',
  cssClass: 'font-noto-sans'
})
mkFonts.set('Noto Serif JP', {
  label: 'Noto Serif',
  value: 'Noto Serif JP',
  url: 'https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap',
  cssClass: 'font-noto-serif'
})
Object.freeze(mkFonts)

export default mkFonts
