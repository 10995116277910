import { defineStore } from 'pinia'
import { userSettingsDefaults, offlineStateDefaults } from '@/services/settings/defaults'
import { ReqUserSettings, AppState, UserInfo, OfflineState, HelpDialogPage, SettingsDialogPage, Nullable } from '@/types'
import mkFonts /*, { MkFont } */ from '@/css/fonts/MkFonts'
import { LARGEST_FONT } from '@/services/settings/applicationSettings'
import { hasCorrectSubTier } from '@/helpers/helpers'

/*
userSettings are synced accross browsers, and can be synced to pwa and mobile device
*/
export const useGlobalStore = defineStore('global-store', {
  state: () => ({
    offlineState: {
      offlineMode: offlineStateDefaults.offlineMode,
      offlineServices: offlineStateDefaults.offlineServices
    } as OfflineState,
    appState: {
      isRefreshing: false,
      initAuthCheckFinished: false,
      userIsAuthed: false,
      firstPage: true,
      history: [],
      drawerOpen: false,
      drawerIsOverlay: false,
      network: window.navigator.onLine,
      signDialogDismissed: false,
      routeRequirement: {},
      appInited: false,
      scrollBar: null,
      helpDialogOpen: false,
      helpDialgPage: null,
      settingsDialogOpen: false,
      settingsDialogPage: null
    } as AppState,
    userSettings: {
      darkMode: userSettingsDefaults.darkMode,
      jpFontFamily: userSettingsDefaults.jpFontFamily,
      jpFontSize: userSettingsDefaults.jpFontSize,
      rubyMode: userSettingsDefaults.rubyMode,
      syncMode: userSettingsDefaults.syncMode,
      lastSettingsUpdate: '',
      newVocabAlwaysShowAll: false
    } as ReqUserSettings,
    userInfo: null as UserInfo | null,
    routerCache: null as Nullable<unknown>
  }),

  getters: {
    jpFontSizeCSSClass (state) {
      if (state.userSettings.jpFontSize === 'smaller') return 'japanese-text--smaller'
      else if (state.userSettings.jpFontSize === 'small') return 'japanese-text--small'
      else if (state.userSettings.jpFontSize === 'normal') return 'japanese-text--normal'
      else if (state.userSettings.jpFontSize === 'large') return 'japanese-text--large'
      else if (state.userSettings.jpFontSize === 'larger') return 'japanese-text--larger'
      else return 'japanese-text--normal'
    },
    vocabHeadJpFontSizeCSSClass () {
      return 'japanese-text-vocab-head'
    },
    vocabExampleJpFontSizeCSSClass () {
      return 'japanese-text-vocab-example'
    },
    largestJapFontSizeCSSClass () {
      return 'japanese-text--larger'
    },
    largestRubyUnderCSSClass () {
      return 'ruby-over'
    },
    largestJpFontFamilyClass () {
      /* largest font may not be loaded, so use default Roboto for time being */
      const daFont = mkFonts.get(LARGEST_FONT)
      if (daFont) return daFont.cssClass
      throw new Error('LARGEST_FONT not avaiable via global-store')
    },
    rubyUnderCSSClass (state) {
      if (state.userSettings.rubyMode === 'top') return 'ruby-over'
      else if (state.userSettings.rubyMode === 'bottom') return 'ruby-under'
      else if (state.userSettings.rubyMode === 'none') return 'no-ruby'
      else return 'ruby-over'
    },
    jpFontFamilyClass (state) {
      const daFont = mkFonts.get(state.userSettings.jpFontFamily)
      if (daFont) return daFont.cssClass
      throw new Error('global-store: invalid jpFontFamily specified')
    },
    isFirstTimeUser (state): boolean {
      return state.userInfo === null
    },
    showSigninDialog (state): boolean {
      // TODO need to check if have network connection
      if (this.isFirstTimeUser) return false
      if (state.appState.initAuthCheckFinished) {
        return !state.appState.signDialogDismissed && !state.appState.userIsAuthed && !this.showSignInPage
      }
      return false
    },
    // dont need to worry about logging out during a user session, this is highly unlikely
    // its more likely a session will expire, and the user will be logged out during refreshSession from the tab visibility event
    showSignInPage (state) : boolean {
      if (this.router.currentRoute.value.name === 'signin') return true
      // only show sign in page if route requires it, and we know we are logged out
      if (Boolean(state.appState.routeRequirement.auth_required) && state.appState.initAuthCheckFinished) {
        // first time user
        if (!this.userInfo) return true
        // first page
        if (state.appState.firstPage) {
          if (!state.appState.userIsAuthed && hasCorrectSubTier(this.userInfo, state.appState.routeRequirement) === false) return true
        } else { // successive navigations
          return state.appState.signDialogDismissed && !state.appState.userIsAuthed
        }
      }
      return false
    },
    showTierPage (state): boolean {
      if (Boolean(state.appState.routeRequirement.auth_required) && state.appState.initAuthCheckFinished && state.appState.userIsAuthed && state.appState.routeRequirement.subscription_required) {
        if (state.appState.routeRequirement.subscription_required === 'tier_0') return false
        if (hasCorrectSubTier(this.userInfo, state.appState.routeRequirement) === false) return true
      }
      return false
    },
    gateCheckingPerms (state): boolean {
      // if (!state.appState.appInited) return true
      // show loading indicator if we know route is tiered, auth status is unconfirmed, and our cached subscription level does not grant access
      if (Boolean(state.appState.routeRequirement.auth_required) && !state.appState.initAuthCheckFinished &&
          state.appState.routeRequirement.subscription_required && (hasCorrectSubTier(this.userInfo, state.appState.routeRequirement) === false)) {
        return true
      }
      return false
    },
    hasLocalOrInternetConnection (state): boolean {
      return Boolean(state.appState.network || process.env.DEBUG_LOCAL_NETWORK)
    }
  },

  actions: {
    openHelpDialog (helpDialogPage: HelpDialogPage) {
      this.appState.helpDialogOpen = true
      this.appState.helpDialgPage = helpDialogPage
    },
    openSettingsDialog (settingsDialogPage: SettingsDialogPage) {
      this.appState.settingsDialogOpen = true
      this.appState.settingsDialogPage = settingsDialogPage
    }
  }
})
