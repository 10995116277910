import auth from './auth'
import network from './network'
import server from './server'
import settings from './settings'
import audio from './audio'
import vocabulary from './vocabulary'
import liebiao from './liebiao'
import system from './system'

export default {
  auth,
  network,
  server,
  settings,
  audio,
  vocabulary,
  liebiao,
  system
}
