import { route } from 'quasar/wrappers'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import { webRoutes } from './routes'
// import { Platform, Screen } from 'quasar'

// This is defined again in boot/init.ts
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function () {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  // const routes = (Platform.is.mobile || Platform.is.cordova || Boolean(process.env.DEBUG_MODE_MOBILE_BROWSER) || Boolean(process.env.DEBUG_MODE_CORDOVA)) ? mobileRoutes : webRoutes
  // const routes = Screen.xs && (Platform.is.cordova || Boolean(process.env.DEBUG_MODE_CORDOVA)) ? mobileRoutes : webRoutes
  const routes = webRoutes
  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    )
  })

  return Router
})
