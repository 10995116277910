import { MkFontFamily, MkFontSize, RubyMode, ReqUserSettings, OfflineState } from '@/types'

export const userSettingsDefaults: ReqUserSettings = {
  darkMode: false,
  jpFontFamily: 'Roboto' as MkFontFamily,
  jpFontSize: 'normal' as MkFontSize,
  rubyMode: 'top' as RubyMode,
  syncMode: true,
  lastSettingsUpdate: '',
  newVocabAlwaysShowAll: false
}

export const offlineStateDefaults: OfflineState = {
  offlineMode: false,
  offlineServices: {
    vocabulary: {
      enabled: false,
      status: 'none',
      last_synced: ''
    },
    dictionary: {
      enabled: false,
      status: 'none',
      last_synced: ''
    },
    lessons: {
      enabled: false,
      status: 'none',
      last_synced: ''
    }
  }
}
