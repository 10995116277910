
export default {
  activating: {
    success: ''
  },
  syncing: {
  },
  created: {
    success: 'New list created',
    failed: 'Failed to create list'
  },
  deleted: {
    singular: {
      success: 'List deleted',
      failed: 'Failed to create list'
    },
    plural: {
      success: 'Lists deleted',
      failed: 'Failed to create lists'
    }
  },
  edited: {
    success: 'List updated',
    failed: 'Failed to update list'
  },
  pages: {
    title: {
      list: 'Lists',
      list_count: '{0} lists',
      select_lists: 'Select lists'
    },
    subtitle: {
      personal: 'Your personal vocabulary lists',
      premade: 'Premade vocabulary lists',
      shared: 'Shared user vocabulary lists'
    }
  }
}
