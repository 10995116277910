import { ErrorObject } from 'ajv'

/* eslint @typescript-eslint/no-explicit-any: 0 */

export class ValidationFailed extends Error {
  constructor (messageBag: ErrorObject<string, Record<string, any>, unknown>[]| null | undefined) {
    super('')
    console.log('Validation failed')
    messageBag?.forEach((errorObj: ErrorObject<string, Record<string, any>, unknown>) => {
      console.log(errorObj)
    })
    this.name = 'Validation Failed'
  }
}
