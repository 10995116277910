// This is just an example,
// so you can safely delete all default props below

export default {
  error: {
    unknown: 'Uknown network error',
    aborted: 'Network time out',
    network: 'Network not responding'
  },
  unavailable: 'Network unavailable. Some features may be disabled.',
  restored: 'Network connection restored.'
}
