import { VOCABULARY_SORT_COLUMN } from '@/enums'
import { VocabularySortParams, Nullable, VocabularySearchParam } from '@/types'

export class VocabularyQueryParams {
  static EMPTY_QUERY_ID = '0'

  public id = 0 // used for comparing different instances
  public sort_params: VocabularySortParams
  public search_params: Nullable<VocabularySearchParam> = null
  public use_cache = false
  public query_id = VocabularyQueryParams.EMPTY_QUERY_ID

  /*
  public stateStoreRequest: Nullable<VocabulayStateStoreRequest> = null
  public storeRequest: Nullable<NewVocabularyData> = null
  public deleteRequest: Nullable<VocabularyDeleteRequest> = null
  public audioFile: Nullable<File> = null
  */

  public constructor () {
    this.sort_params = {
      column: VOCABULARY_SORT_COLUMN.DATE_CREATED,
      order: 'desc',
      page: 0,
      page_size: 25
    }
  }
}
