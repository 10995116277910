import { MkFontFamily } from '@/types'

export const MAX_HISTORY_SAVED = 10
export const LARGEST_FONT: MkFontFamily = 'Roboto'
export const TOAST_TIMEOUT = 8000
export const AUDIO_FILE_CHUNK_SIZE = 30
export const VOCABULARY_MAX_VARIANTS = 6
export const VOCABULARY_MAX_ENGLISHES = 14
export const VOCABULARY_MAX_EXAMPLES = 20

export const USER_INPUT_SEP_CHAR = '⚔⚘♷'

export const LIEBIAO_DO_QUIZ_DEFAULT = true
