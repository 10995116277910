export default {
  error: {
    failed: 'Failed to save settings.'
  },
  messages: {
    onAppModeChange: {
      toNotAppMode: 'Please refresh the page to use this screen size.',
      toAppMode: 'Please refresh the page to use this screen size.'
    }
  },
  actions: {
    refresh: 'Refresh'
  }
}
