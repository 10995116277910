import { AudioState, Nullable, AudioData } from '@/types'
import { API_VOCABULARY_GET_AUDIO } from '@/router/routes'
import { Notify } from 'quasar'
import { i18n } from '@/boot/i18n'
import { TOAST_TIMEOUT } from '@/services/settings/applicationSettings'
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '@/stores/global-store'
// import type { DatabaseService } from '@/services/database/DatabaseService'
// import { VocabularyDB } from '@/services/database/vocabularyDB'
import type { OfflineService } from '@/services/offline/OfflineService'

export class AudioService {
  public audioState: AudioState = 'stopped'
  private audioObject: Nullable<HTMLAudioElement> = null
  private stoppedCallback: Nullable<() => void> = null
  private globalStore = useGlobalStore()
  private offlineService: Nullable<OfflineService> = null
  // private databaseService: Nullable<DatabaseService>

  public setOfflineService (offlineService: OfflineService): void {
    this.offlineService = offlineService
  }

  private getAudio (audioId: number): Promise<HTMLAudioElement> {
    const { offlineState } = storeToRefs(this.globalStore)
    return new Promise<HTMLAudioElement>((resolve, reject) => {
      if (offlineState.value.offlineMode && !this.globalStore.hasLocalOrInternetConnection) {
        if (!this.offlineService) {
          reject('OfflineService service not available')
          return
        }
        console.log('reading offline file...')
        this.offlineService.databaseService.getAudio(audioId).then((audioData: AudioData) => {
          if (audioData.audio_blob) {
            resolve(new Audio(URL.createObjectURL(audioData.audio_blob)))
          }
          reject('AudioData object does not have property "as_blob" set')
        }).catch(err => {
          reject(err)
        })
      } else {
        resolve(new Audio(`${API_VOCABULARY_GET_AUDIO}/${audioId}`))
      }
    })
  }

  public playAudio (audioId: number, stopepdCallback: () => void): Promise<void> {
    // TODO check MDN notes about about audio lifecycle to ensure a memory leak is not being created here
    if (this.stoppedCallback) this.stoppedCallback()
    this.stoppedCallback = stopepdCallback
    this.audioState = 'stopped'
    if (this.audioObject) this.audioObject.pause()
    this.audioObject = null

    return new Promise((resolve, reject) => {
      this.getAudio(audioId).then((audioObj: HTMLAudioElement) => {
        this.audioObject = audioObj
        this.audioObject.currentTime = 0
        this.audioObject.autoplay = true
        if (this.audioObject) {
          console.log('playing...')
          this.audioObject.addEventListener('ended', () => {
            if (this.audioState !== 'stopped') this.audioState = 'stopped'
            stopepdCallback()
          })
          this.audioState = 'playing'
          this.audioObject.play().catch((error) => {
            this.audioState = 'stopped'
            if (error.name === 'NotAllowedError') {
              Notify.create({
                timeout: TOAST_TIMEOUT,
                message: i18n.global.t('audio.error.not_allowed'),
                type: 'negative'
              })
            }
          })
          resolve()
        } else {
          console.log('not playing...')
          reject()
        }
      })
    })
  }

  public stopAudio (): void {
    if (this.stoppedCallback) this.stoppedCallback()
    if (this.audioObject) {
      this.audioObject.pause()
    }
  }
}
