import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import { defer, from, retry, Subscription /* throwError */ } from 'rxjs'
import { genericRetryStrategy } from '@/helpers/helpers'
import { Notify, Platform } from 'quasar'
import { i18n } from '@/boot/i18n'
import { platformCordova } from '@/boot/init'
// import { API_GET_SANCTUM_TOKEN } from '@/router/routes'
import { StorageService } from '@/services/storage/StorageService'
import { STORAGE_KEYS } from '@/enums'
// import CookieParser, { Cookie } from 'set-cookie-parser'
// import { Nullable } from '@/types'
// import FormData2 from 'form-data'
// import { CapacitorHttp, HttpResponse, HttpOptions, HttpHeaders } from 'capacitor/@capacitor/core'
// import { HTTP, HTTPResponse } from 'cordova/@awesome-cordova-plugins/http'
const cookieMaster = require('cordova/cordova-plugin-cartegraph-cookie-master/www/cookieMaster') // TODO this needs to be imported only on cordova
import { TOAST_TIMEOUT } from '@/services/settings/applicationSettings'
import { useGlobalStore } from '@/stores/global-store'
// import { storeToRefs } from 'pinia'

export class HttpService {
  private baseURL = process.env.API
  private globalStore: ReturnType<typeof useGlobalStore>
  private client: AxiosInstance
  private storageService: StorageService
  public readonly excludedStatusCodes: Array<number> = [419, 422, 401, 404, 403, 500]
  // private cookies: Nullable<Cookie[]> = null
  // private xsrf_token = ''
  private apiToken: string | null = ''

  constructor (storageService: StorageService) {
    this.storageService = storageService
    this.client = axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      timeout: 20000
    })
    this.globalStore = useGlobalStore()
  }

  /*
  public isOnline (): boolean {
    return window.navigator.onLine
  }
  */

  /*
  loadStoredCookies (): Promise<Cookie[]> {
    return new Promise<Cookie[]>((resolve, reject) => {
      this.storageService.getValue(STORAGE_KEYS.COOKIES).then((result) => {
        const cookiesObj: {cookies: Cookie[]} = JSON.parse(result)
        if (!cookiesObj) {
          console.log('no stored cookies!')
          reject('no stored cookies!')
        }
        console.log('stored:')
        console.log(cookiesObj)
        this.cookies = cookiesObj.cookies
        resolve(this.cookies)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getCookies (): Promise<Cookie[]> {
    if (this.cookies) {
      return Promise.resolve(this.cookies)
    }
    return this.loadStoredCookies()
  }

  storeCookies (cookieString: string): Promise<void> {
    console.log('storeCookies')
    let parsedCookies: Cookie[] = []
    for (const singleCookieString of CookieParser.splitCookiesString(cookieString)) {
      const parsedCookie = CookieParser.parse(singleCookieString, {
        decodeValues: true
      })
      parsedCookies = parsedCookies.concat(parsedCookie)
    }
    console.log('parsedCookies:')
    console.log(parsedCookies)
    for (const parsedCookie of parsedCookies) {
      if (parsedCookie.name === 'XSRF-TOKEN') {
        this.xsrf_token = parsedCookie.value
      }
    }
    return this.storageService.storeValue(STORAGE_KEYS.COOKIES, JSON.stringify({ cookies: parsedCookies }))
  }
  */

  getXSRFToken (): Promise<string> {
    return new Promise<string>((resolve) => {
      if (Platform.is.cordova) {
        cookieMaster.getCookieValue(`${this.baseURL}`, 'XSRF-TOKEN', (data: {cookieValue: string}) => {
          if (data.cookieValue) {
            resolve(decodeURIComponent(data.cookieValue))
          } else {
            // console.log('cookieMaster failed to get XSRF-TOKEN')
            resolve('')
          }
        },
        () => {
          // console.log('cookieMaster failed to get XSRF-TOKEN')
          // console.log(err)
          resolve('')
        })
      } else {
        resolve('')
      }
    })
  }

  setAPIToken (token: string): void {
    this.apiToken = token
    this.storageService.storeValue(STORAGE_KEYS.TOKEN, token)
  }

  getAPIToken (): string | null {
    if (!this.apiToken) {
      const apiToken = this.storageService.getValue(STORAGE_KEYS.TOKEN)
      if (apiToken) {
        this.apiToken = apiToken
      }
    }
    return this.apiToken
  }

  clearCookies (): void {
    if (Platform.is.cordova) {
      cookieMaster.clearCookies()
    }
  }

  buildRequestHeaders (): Promise<{ headers?: Record<string, string>}> {
    return new Promise<{ headers?: Record<string, string>}>((resolve) => {
      this.getXSRFToken().then((xsrfToken) => {
        const headersPayload: { headers?: Record<string, string>} = {}
        if (platformCordova) {
          if (this.getAPIToken()) {
            if (!headersPayload.headers) headersPayload.headers = {}
            headersPayload.headers.Authorization = `Bearer ${this.getAPIToken()}`
          }
          if (xsrfToken && process.env.XSRF_TOKEN_NAME) {
            if (!headersPayload.headers) headersPayload.headers = {}
            headersPayload.headers[process.env.XSRF_TOKEN_NAME] = xsrfToken
          }
        }
        resolve(headersPayload)
      })
    })

    /*
    return new Promise<{ headers?: Record<string, string>}>((resolve, reject) => {
      this.getXSRFToken().then((xsrfToken) => {
        const headersPayload: { headers?: Record<string, string>} = {}
        if (Platform.is.cordova) {
          if (xsrfToken && process.env.XSRF_TOKEN_NAME) {
            if (!headersPayload.headers) headersPayload.headers = {}
            headersPayload.headers[process.env.XSRF_TOKEN_NAME] = xsrfToken
          }
          if (isFormData && requestType === 'post') {
            // if (!headersPayload.headers) headersPayload.headers = {}
            // headersPayload.headers['Content-Type'] = 'multipart/form-data' // ; boundary=----WebKitFormBoundaryiUAznpmo2VI9PC0r
          }
          // if (!headersPayload.headers) headersPayload.headers = {}
          // headersPayload.headers.Origin = 'https://localhost'
          // headersPayload.headers.Origin = 'https://pwa.v2.manakyun.com'
        }
        resolve(headersPayload)
      }).catch((err) => {
        console.log('Could not build request headers')
        reject(err)
      })
    })
    */
  }

  /**
   * Issuess an plain post request. Usually postWithRetry() is a safer choice
   * @type {D} The type of the payload
   * @type {R} The type of the response
   * @param {string} url - The post route
   * @param {D} data - The payload
   * @returns {Promise<AxiosResponse<R>>}
   */
  post<D, R> (url: string, data?: D): Promise<AxiosResponse<R>> {
    // TODO save route, when response arrives check current route, if route has changed, then dont issue any notifications
    return new Promise<AxiosResponse<R>>((resolve, reject) => {
      const daData = data instanceof FormData ? data : data as FormData
      this.buildRequestHeaders().then((headers) => {
        // console.log('post request headers:')
        // console.log(headers)
        this.client.post<D, AxiosResponse<R & {api_token: string}>>(url, daData, headers).then((response: AxiosResponse<R & {api_token: string}>) => {
          // console.log('postResponse: ')
          // console.log(response)
          if (response.data.api_token) {
            this.setAPIToken(response.data.api_token)
            this.clearCookies()
          }
          resolve(response)
        }).catch((err) => {
          console.log('http post error')
          console.log(err)
          this.handleHttpResponseError(err)
          reject(err)
        })
      })
    })
  }

  /**
   * Issuess an unbuffered get request. Usually getWithRetry() is a safer choice
   * @type {R} The type of the response
   * @param {string} url - The post route
   * @returns {Promise<AxiosResponse<R>>}
   */
  get<R> (url: string): Promise<R & {api_token: string}> {
    return new Promise<R & {api_token: string}>((resolve, reject) => {
      this.buildRequestHeaders().then((headers) => {
        this.client.get<R & {api_token: string}>(url, headers).then((response: AxiosResponse<R & {api_token: string}>) => {
          // console.log('getRepsonse: ')
          // console.log(response)
          if (response.data.api_token) {
            this.setAPIToken(response.data.api_token)
          }
          resolve(response.data)
        }).catch((err) => {
          console.log('http get error')
          this.handleHttpResponseError(err)
          reject(err)
        })
      })
    })
  }

  /**
   * Issuess a post request, but will retry if request fails
   * @type {D} The type of the payload
   * @type {R} The type of the response
   * @param {string} url - The post route
   * @param {D} data - The payload
   * @param {number[]} excludedStatusCodes - Status codes that will not trigger a retry
   * @returns {ObservPromiseable<AxiosResponse<R>>}
   */
  public postWithRetry<D, R> (url: string, data?: D, excludedStatusCodes: Array<number> = this.excludedStatusCodes): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      const serverResponse: Subscription = from(defer(() => this.post<D, R>(url, data))).pipe(
        retry({ delay: genericRetryStrategy({ excludedStatusCodes }) })
        /*
        retry({
          delay: (error: AxiosError, retryCount: number) => {
            if (retryCount < 2 && error.response?.status === 419) {
              return this.get<boolean>(API_GET_SANCTUM_TOKEN)
            } else {
              return throwError(() => error)
            }
          }
        })
        */
      ).subscribe({
        next: (response: AxiosResponse<R>) => {
          resolve(response.data)
          serverResponse.unsubscribe()
        },
        error: (error) => {
          reject(error)
          serverResponse.unsubscribe()
        }
      })
    })
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public handleHttpResponseError (error: AxiosError): void {
    if (!error.response) {
      // axios timeouts, network disabled
      // 'ECONNABORTED' == request aborted
      // 'ERR_NETWORK'
      let errMsg = i18n.global.t('network.error.unknown')
      if (error.code === 'ECONNABORTED') errMsg = i18n.global.t('network.error.aborted')
      if (error.code === 'ERR_NETWORK') errMsg = i18n.global.t('network.error.network')
      this.notifyNetworkError(errMsg)
      // this.notifyNetworkError(process.env.DEBUGGING ? `${error.toString()}` : errMsg)
    } else {
      if (error.response?.status === 500) {
        this.notifyNetworkError(i18n.global.t('server.error.unknown'))
      }
    }
  }

  /**
     * Issuess a post request, but will retry if request fails
     * @type {R} The type of the response
     * @param {string} url - The post route
     * @param {number[]} excludedStatusCodes - Status codes that will not trigger a retry
     * @returns {ObservPromiseable<AxiosResponse<R>>}
     */
  public getWithRetry<R> (url: string, excludedStatusCodes: Array<number> = this.excludedStatusCodes): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      const serverResponse: Subscription = from(defer(() => this.get<R>(url))).pipe(
        retry({ delay: genericRetryStrategy({ excludedStatusCodes }) })
      ).subscribe({
        next: (responseData: R) => {
          resolve(responseData)
          serverResponse.unsubscribe()
        },
        error: (error) => {
          if (!error.response) {
            // axios timeouts, network disabled
            this.notifyNetworkError(process.env.DEBUGGING ? `${error.toString()}` : i18n.global.t('network.error.unknown'))
          } else {
            if (error.response?.status === 500) {
              this.notifyNetworkError(i18n.global.t('server.error.unknown'))
            }
          }
          reject(error)
          serverResponse.unsubscribe()
        }
      })
    })
  }

  public fetchBlob (url: string, excludedStatusCodes: Array<number> = this.excludedStatusCodes): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      const serverResponse: Subscription = from(defer(() => fetch(url))).pipe(
        retry({ delay: genericRetryStrategy({ excludedStatusCodes }) })
      ).subscribe({
        next: (responseData: Response) => {
          resolve(responseData.blob())
          serverResponse.unsubscribe()
        },
        error: (error) => {
          if (!error.response) {
            // axios timeouts, network disabled
            this.notifyNetworkError(process.env.DEBUGGING ? `${error.toString()}` : i18n.global.t('network.error.unknown'))
          } else {
            if (error.response?.status === 500) {
              this.notifyNetworkError(i18n.global.t('server.error.unknown'))
            }
          }
          reject(error)
          serverResponse.unsubscribe()
        }
      })
    })
  }

  public notifyNetworkError (msg: string): void {
    // set a small delay so other services have time to update their notifications first, also Notify gets confused if you create multiple
    // notifications at the same time
    setTimeout(() => {
      Notify.create({
        timeout: TOAST_TIMEOUT,
        message: msg,
        type: 'negative'
      })
    }, 200)
  }

  /*
  public hasLocalOrInternetConnection (): boolean {
    const { appState } = storeToRefs(this.globalStore)
    return Boolean(appState.value.network || process.env.DEBUG_LOCAL_NETWORK)
  }
  */
}
