
export default {
  activating: {
    success: ''
  },
  syncing: {
    starting: '',
    success: 'Vocabulary synced',
    failed: 'Failed to sync vocabulary. Check your network connection and try again.'
  },
  statuses: {
    unfamiliar: 'Unfamiliar',
    learning: 'Learning',
    reviewing: 'Reviewing',
    maintaining: 'Maintaining',
    mastered: 'Mastered'
  },
  created: {
    success: 'Added new vocabulary',
    failed: 'Failed to add vocabulary'
  },
  pages: {
    edit: {
      title: 'Edit Vocabulary'
    },
    create: {
      title: 'Add New Vocabulary'
    }
  }
}
