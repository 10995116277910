import type { InjectionKey } from 'vue'
import { Nullable } from '@/types'
import { HttpService } from '@/services/http/HttpService'
import { AuthService } from '@/services/auth/AuthService'
import { VocabularyService } from '@/services/vocabulary/VocabularyService'
import { SettingsService } from '@/services/settings/SettingsService'
import { StorageService } from '@/services/storage/StorageService'
import { OfflineService } from '@/services/offline/OfflineService'
// import type { ParsingService } from '@/services/offline/ParsingService'
import { FileService } from '@/services/filesys/FileService'
import { AudioService } from '@/services/audio/AudioService'
// import type { DatabaseService } from '@/services/database/DatabaseService'

export const HTTP_SERVICE_KEY = Symbol('Http service') as InjectionKey<HttpService>
export const AUTH_SERVICE_KEY = Symbol('Auth service') as InjectionKey<AuthService>
export const VOCABULARY_SERVICE_KEY = Symbol('Vocabulary service') as InjectionKey<VocabularyService>
export const SETTINGS_SERVICE_KEY = Symbol('Settings service') as InjectionKey<SettingsService>
export const STORAGE_SERVICE_KEY = Symbol('Settings service') as InjectionKey<StorageService>
export const OFFLINE_SERVICE_KEY = Symbol('Offline service') as InjectionKey<Nullable<OfflineService>>
export const FILE_SERVICE_KEY = Symbol('File service') as InjectionKey<FileService>
export const AUDIO_SERVICE_KEY = Symbol('Audio service') as InjectionKey<AudioService>
// export const DATABSE_SERVICE_KEY = Symbol('Database service') as InjectionKey<Ref<Nullable<DatabaseService>>>

// helpful app constants
export const PLATFORM_CORDOVA = Symbol('Runtime platform is cordova') as InjectionKey<boolean>
export const PLATFORM_MOBILE_BROWSER = Symbol('Runtime platform is mobile browser') as InjectionKey<boolean>
export const PLATFORM_DESKTOP = Symbol('Runtime platform is desktop browser') as InjectionKey<boolean>
export const PLATFORM_APP_MODE = Symbol('Runtime platform is app mode') as InjectionKey<boolean>
