export const enum VOCABULARY_SORT_COLUMN {
  DATE_CREATED = 'created_at',
  JAPANESE = 'japanese',
  TRANSLATION = 'translation_searchable',
  TIMES_QUIZZED = 'times_quizzed',
  LAST_QUIZZED = 'last_quizzed',
}

export const enum LIEBIAO_SORT_COLUMN {
  DATE_CREATED = 'created_at',
  TITLE = 'title',
  VOCABULARY_COUNT = 'vocabulary_count',
  TIMES_QUIZZED = 'times_quizzed',
  LAST_QUIZZD = 'last_quizzed',
}

export const enum TAG_SORT_COLUMN {
  DATE_CREATED = 'created_at',
  TITLE = 'title',
  VOCABULARY_COUNT = 'vocabulary_count',
}

export const enum POSTAG_SORT_COLUMN {
  TITLE = 'title',
}

export const enum VOCABULARY_TEXT_SEARCH_COLUMN {
  JAPANESE = 1,
  YOMIKATA = 2,
  TRANSLATION = 3,
  NOTE = 4,
  HINT = 5,
}

export const enum STORAGE_KEYS {
 USER_SETTINGS = 'user_settings',
 USER_INFO = 'user_info',
 TOKEN = 'token',
 DEVICE_NAME = 'device_id',
 OFFLINE_STATE = 'offline_state'
}

// https://en.wikipedia.org/wiki/Plane_(Unicode)#Basic_Multilingual_Plane
export const enum CJKRANGES {
  KANJI_START = '\u4e00',
  KANJI_END = '\u9faf',
  KANJI_EXTENDED_START = '\u3400',
  KANJI_EXTENDED_END = '\u4dbf',
  HIRAGANA_START = '\u3040',
  HIRAGANA_END = '\u309f',
  KATAKANA_START = '\u30a0',
  KATAKANA_END = '\u30ff',
  CJK_PUNCT_START = '\u3000',
  CJK_PUNCT_END = '\u303f'
}
