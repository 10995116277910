import { useGlobalStore } from '@/stores/global-store'
import { HttpService } from '@/services/http/HttpService'
import { SettingsService } from '@/services/settings/SettingsService'
import { StorageService } from '@/services/storage/StorageService'
import { UserInfo, LoginRequest, UserSettingsData, OfflineSyncStatus, Nullable } from '@/types'
import { STORAGE_KEYS } from '@/enums'
import { API_GET_USER, API_LOGIN_ROUTE, API_GET_SANCTUM_TOKEN, API_LOGIN_ROUTE_MOBILE } from '@/router/routes'
import Ajv, { JSONSchemaType } from 'ajv'
import addFormats from 'ajv-formats'
import { ValidationFailed } from '@/exceptions'
import { storeToRefs } from 'pinia'
// import { defer, from, retry, Subscription } from 'rxjs'
import { Notify } from 'quasar'
import { i18n } from '@/boot/i18n'
import { platformCordova } from '@/boot/init'

interface UserSessionInfo {
  userInfo: UserInfo
  userSettings?: UserSettingsData
  sync_status?: OfflineSyncStatus
}

interface SessionRefreshRequest {
  lastSettingsUpdate?: string
  last_vocab_sync_date?: string
}

const userInfoDataSchema: JSONSchemaType<UserSessionInfo> = {
  type: 'object',
  properties: {
    userInfo: {
      type: 'object',
      nullable: false,
      properties: {
        id: { type: 'integer', minimum: 1 },
        name: { type: 'string', nullable: false },
        email: { type: 'string', nullable: true },
        joined: { type: 'string', nullable: true },
        roles: {
          type: 'array',
          nullable: false,
          items: {
            type: 'string'
          }
        },
        subscription: { type: 'string', nullable: false }
      },
      required: ['id', 'roles', 'subscription']
    },
    userSettings: {
      type: 'object',
      nullable: true,
      properties: {
        darkMode: { type: 'integer', minimum: 0, maximum: 1, nullable: true },
        jpFontSize: { type: 'string', nullable: true },
        jpFontFamily: { type: 'string', nullable: true },
        rubyMode: { type: 'string', nullable: true },
        syncMode: { type: 'integer', nullable: true },
        lastSettingsUpdate: { type: 'string', nullable: true },
        newVocabAlwaysShowAll: { type: 'boolean', nullable: true }
      }
    },
    sync_status: {
      type: 'object',
      nullable: true,
      properties: {
        vocabulary: { type: 'string', nullable: true }
      }
    }
  },
  required: ['userInfo']
}

const ajv = new Ajv()
addFormats(ajv)
const userInfoDataAalidator = ajv.compile<UserSessionInfo>(userInfoDataSchema)

export class AuthService {
  private httpService: HttpService
  private settingsService: SettingsService
  private globalStore: ReturnType<typeof useGlobalStore>
  public hasSanctumToken: Promise<boolean>
  // private notSignedInNotice: Nullable<ReturnType<typeof Notify.create>> = null
  // private router: Router
  private storageService: StorageService

  constructor (/* router: Router, */ httpService: HttpService, settingsService: SettingsService, storageService: StorageService) {
    this.httpService = httpService
    this.settingsService = settingsService
    this.storageService = storageService
    this.globalStore = useGlobalStore()
    // this.router = router

    this.hasSanctumToken = Promise.resolve(false)
  }

  public refreshUserSession (): Promise<Nullable<OfflineSyncStatus>> {
    const { userInfo, appState, userSettings, offlineState } = storeToRefs(this.globalStore)
    if (!this.globalStore.hasLocalOrInternetConnection) return Promise.resolve(null)

    if (this.globalStore.appState.isRefreshing) return Promise.resolve(null)
    this.globalStore.appState.isRefreshing = true
    userInfo.value = this.getUserFromStorage()

    const refreshRequst: SessionRefreshRequest = {}
    if (userSettings.value.syncMode && userSettings.value.lastSettingsUpdate) {
      refreshRequst.lastSettingsUpdate = userSettings.value.lastSettingsUpdate
    }
    if (offlineState.value.offlineMode && offlineState.value.offlineServices.vocabulary.enabled && offlineState.value.offlineServices.vocabulary.last_synced) {
      refreshRequst.last_vocab_sync_date = offlineState.value.offlineServices.vocabulary.last_synced
    }

    // fetch userInfo from server and update
    return new Promise<Nullable<OfflineSyncStatus>>((resolve, reject) => {
      this.httpService.postWithRetry<SessionRefreshRequest, UserSessionInfo>(API_GET_USER, refreshRequst).then((userSessionInfo: UserSessionInfo) => {
        if (process.env.DEBUGGING) {
          if (!userInfoDataAalidator(userSessionInfo)) {
            throw new ValidationFailed(userInfoDataAalidator.errors)
          }
        }
        userInfo.value = userSessionInfo.userInfo
        this.saveUserToStorage(userInfo.value)
        if (userSettings.value.syncMode && userSessionInfo.userSettings) {
          this.settingsService.applyUserSettingsFromServer(this.settingsService.makeUserSettings(userSessionInfo.userSettings))
        }
        appState.value.userIsAuthed = true
        this.globalStore.appState.isRefreshing = false
        resolve(userSessionInfo.sync_status ?? null)
      }).catch((error) => {
        this.fetchSanctumToken() // fetch token now so next request doesnt fail with 419
        // if response is 401, or 419, then we are likely signed out.
        if ((error.response?.status === 401 || error.response?.status === 419) && !this.globalStore.isFirstTimeUser) {
          // this.globalStore.requestSigninDialog(true)
        }
        /*
        if ((error.response?.status === 401 || error.response?.status === 419) && !this.globalStore.isFirstTimeUser && !this.notSignedInNotice) {
          if (this.router.currentRoute.value.name !== 'signin') {
            this.notSignedInNotice = Notify.create({
              type: 'warning',
              timeout: 0,
              message: i18n.global.t('auth.session.expired'),
              actions: [
                {
                  label: i18n.global.t('auth.signin.signInBtn'),
                  handler: () => {
                    this.router.push({ name: 'signin' })
                    this.closeSignInNotice()
                  }
                }
              ]
            })
          }
        }
        */
        appState.value.userIsAuthed = false
        this.globalStore.appState.isRefreshing = false
        reject(error)
      })
    })
  }

  private saveUserToStorage (userInfo: UserInfo): boolean {
    // console.log('saving user to storage')
    try {
      this.storageService.storeValue(STORAGE_KEYS.USER_INFO, JSON.stringify(userInfo))
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  private getUserFromStorage (): UserInfo | null {
    const userResult = this.storageService.getValue(STORAGE_KEYS.USER_INFO)
    if (userResult) {
      return JSON.parse(userResult) as UserInfo
    } else {
      return null
    }
  }

  public fetchSanctumToken (): Promise<boolean> {
    this.hasSanctumToken = new Promise<boolean>((resolve, reject) => {
      this.httpService.getWithRetry<boolean>(API_GET_SANCTUM_TOKEN).then(() => {
        resolve(true)
      }).catch((error) => {
        reject(error)
      })
    })
    return this.hasSanctumToken
  }

  /*
  public closeSignInNotice (): void {
    if (this.notSignedInNotice) this.notSignedInNotice()
    this.notSignedInNotice = null
  }
  */

  public loginUser (userCred: LoginRequest): Promise<boolean> {
    const { userInfo, appState } = storeToRefs(this.globalStore)
    let loginRoute = API_LOGIN_ROUTE
    if (platformCordova) {
      loginRoute = API_LOGIN_ROUTE_MOBILE
      const deviceName = this.storageService.getValue(STORAGE_KEYS.DEVICE_NAME)
      if (deviceName) {
        userCred.device_name = deviceName
      }
    }
    return new Promise<boolean>((resolve, reject) => {
      this.httpService.postWithRetry<LoginRequest, UserSessionInfo>(loginRoute, userCred).then((userSessionInfo: UserSessionInfo) => {
        if (process.env.DEBUGGING) {
          if (!userInfoDataAalidator(userSessionInfo)) {
            throw new ValidationFailed(userInfoDataAalidator.errors)
          }
        }
        userInfo.value = userSessionInfo.userInfo
        this.saveUserToStorage(userInfo.value)
        if (userSessionInfo.userSettings) {
          this.settingsService.applyUserSettingsFromServer(this.settingsService.makeUserSettings(userSessionInfo.userSettings))
        }
        appState.value.userIsAuthed = true
        appState.value.signDialogDismissed = false
        // this.globalStore.requestSigninDialog(false)
        // this.closeSignInNotice()
        Notify.create({
          type: 'info',
          message: i18n.global.t('auth.signin.welcomeBack')
        })
        resolve(true)
      }).catch((error) => {
        appState.value.userIsAuthed = false
        reject(error)
      })
    })
  }
}
