// This is just an example,
// so you can safely delete all default props below

export default {
  signin: {
    failed: 'Sorry, we can\'t find an account with this email address. Please try again or create a new account.',
    welcomeBack: 'Welcome back!',
    signInBtn: 'Sign In'
  },
  session: {
    expired: 'Your session has expired. Please sign in again.'
  }
}
