import { from, switchMap, defer, concat, catchError, Observable, throwError, timer, debounceTime, throttle, merge, filter, Observer, Subject, takeUntil, combineLatest, map, startWith, distinctUntilChanged, take, Subscription, of, ReplaySubject } from 'rxjs'
import { AxiosError } from 'axios'
import { onBeforeUnmount, ref as _ref, Ref as _Ref, watch, WatchStopHandle } from 'vue'
import { UserInfo, RouteRequirement, Nullable, JPTokenStream, CJKComposition, JPToken } from '@/types'
import { CJKRANGES } from '@/enums'
import { USER_INPUT_SEP_CHAR } from '@/services/settings/applicationSettings'

const reKanji = new RegExp(`[${CJKRANGES.KANJI_START}-${CJKRANGES.KANJI_END}]|[${CJKRANGES.KANJI_EXTENDED_START}-${CJKRANGES.KANJI_EXTENDED_END}]`, '')
const reKana = new RegExp(`[${CJKRANGES.HIRAGANA_START}-${CJKRANGES.HIRAGANA_END}]|[${CJKRANGES.KATAKANA_START}-${CJKRANGES.KATAKANA_END}]`, '')
const kanjiStartCP = CJKRANGES.KANJI_START.charCodeAt(0)
const kanjiEndCP = CJKRANGES.KANJI_END.charCodeAt(0)
const kanjiextStartCP = CJKRANGES.KANJI_EXTENDED_START.charCodeAt(0)
const kanjiextEndCP = CJKRANGES.KANJI_EXTENDED_END.charCodeAt(0)
/*
const hiraganaStartCP = CJKRANGES.HIRAGANA_START.charCodeAt(0)
const hiraganEndCP = CJKRANGES.HIRAGANA_END.charCodeAt(0)
const katakanaStartCP = CJKRANGES.KATAKANA_START.charCodeAt(0)
const katakanaEndCP = CJKRANGES.KATAKANA_END.charCodeAt(0)
*/

export type RxRef<T> = _Ref<T> & Observer<T> & Observable<T> & {silentUpdate: (val: T) => void, unsubscribe: () => void};

// https://dev.to/noprod/rx-composition-api-vue-js-rxjs-4h3h
// rxref allows us to use vue references as rxjs streams, great for managing unpredictable user input!
export function rxref<T> (value: T): RxRef<T> {
  const $ref = _ref(value) as RxRef<T>
  const subject = new Subject<T>()

  const applyWatcher: () => WatchStopHandle = () => {
    return watch($ref, (val) => {
      subject.next(val)
    })
  }
  let stopper: WatchStopHandle = applyWatcher()

  // extend your ref to rx and bind context
  $ref.next = subject.next.bind(subject)
  $ref.pipe = subject.pipe.bind(subject)
  $ref.subscribe = subject.subscribe.bind(subject)
  $ref.unsubscribe = () => {
    subject.unsubscribe()
    stopper()
  }
  // update the underlying stream without triggering any subscribers or watchers
  $ref.silentUpdate = (val: T) => {
    stopper()
    $ref.value = val
    stopper = applyWatcher()
  }

  // Don't forget to unsubscribe or you will get memory leaks
  // cant use onBeforeUnmount here because the rxref might be created asynchronously
  /* onBeforeUnmount(() => {
    subject.unsubscribe()
    stopper()
  }) */
  return $ref
}

export function deeprxref<T> (value: () => T): RxRef<T> {
  const $ref = _ref(value()) as RxRef<T>
  const subject = new Subject<T>()

  const applyWatcher: () => WatchStopHandle = () => {
    return watch(value, (val) => {
      subject.next(val)
    })
  }
  let stopper: WatchStopHandle = applyWatcher()

  // extend your ref to rx and bind context
  $ref.next = subject.next.bind(subject)
  $ref.pipe = subject.pipe.bind(subject)
  $ref.subscribe = subject.subscribe.bind(subject)
  $ref.silentUpdate = (val: T) => {
    stopper()
    $ref.value = val
    stopper = applyWatcher()
  }

  // Don't forget to unsubscribe or you will get memory leaks
  onBeforeUnmount(() => {
    subject.unsubscribe()
    stopper()
  })
  return $ref
}

// https://www.learnrxjs.io/learn-rxjs/operators/error_handling/retrywhen
export const genericRetryStrategy = ({
  maxRetryAttempts = 2,
  scalingDuration = 800,
  excludedStatusCodes = []
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  excludedStatusCodes?: number[]
} = {}) => (error: AxiosError, retryCount: number) => {
  if (retryCount > maxRetryAttempts || excludedStatusCodes.find(e => e === error?.response?.status)) {
    return throwError(() => error)
  }
  if (error.code !== 'ECONNABORTED' && !error.response) {
    return throwError(() => error) // if there is no response, and not from an abortion, then dont retry
  }
  // retry after 1s, 2s, etc...
  return timer(/* retryCount * scalingDuration */ scalingDuration)
}

export function generateID (): number {
  return Math.floor(Math.random() * Date.now())
}

// TODO need to account for errors?
export function dethrottle<T> (source: Observable<T>, timeToDebounce = 400): Observable<T> {
  const debounced: Observable<T> = source.pipe(
    debounceTime(timeToDebounce)
  )
  const throttled: Observable<T> = source.pipe(
    throttle(() => debounced)
  )

  let lastEmission: T | null = null
  const merged: Observable<T> = merge(debounced, throttled).pipe(
    filter((currentEmission: T) => {
      if (!lastEmission) {
        lastEmission = currentEmission
        return true
      }
      if (lastEmission !== currentEmission) {
        lastEmission = currentEmission
        return true
      }
      return false
    })
  )

  return merged
}

// this function returns an anti-flicker observable
// behavior:
// 1) Do nothing if promsive/obsvervable resolves before delayBeforeLoader
// 2) If promise/observable does not resolve within delayBeforeLoader, then trigger loader (emit true)
// 3) Loader stays true until promsive/observable resolves, but stays true for at least minLoaderDuration
export function gracefulLoad<T> (source: Promise<T> | Observable<T>, delayBeforeLoader = 400, minLoaderDuration = 400): Observable<boolean> {
  const startTime = Date.now()
  const sharedSource = from(source)
  const onTimer: Observable<boolean> = timer(delayBeforeLoader).pipe(map(() => true))
  const offTimer: Observable<boolean> = timer(delayBeforeLoader + minLoaderDuration).pipe(map(() => false))
  /*
  const maxTimer: Observable<boolean> = timer(delayBeforeLoader + maxLoaderDuration).pipe(map(() => {
    throw new Error('Loading timed out')
  }))
  */
  const showLoadingIndicator = merge(
    // ON in X seconds
    onTimer.pipe(takeUntil(sharedSource)),
    // OFF once we receive a result, yet at least Xs
    combineLatest([sharedSource, offTimer]).pipe(map(() => false))
    // maxTimer
  ).pipe(
    startWith(false),
    distinctUntilChanged(),
    take(3),
    catchError((error: unknown) => {
      const now = Date.now()
      let remainingTime = 0
      if (now > startTime + delayBeforeLoader) {
        remainingTime = Math.max(startTime + minLoaderDuration + delayBeforeLoader - now, 0)
      }
      return timer(remainingTime).pipe(
        switchMap(() => {
          return throwError(() => error)
        })
      )
    })
  )
  return defer(() => showLoadingIndicator)
}

// min delay if promise/observable not resolved at time of subscription, otheriwse no delay
// when promise/observable resolves, ensure at least a delay of minLoaderDuration
// source must be a replay subject of length 1 that emits false initially, then emits true when data is loaded
export function minDelayIfNotLoaded (source: ReplaySubject<boolean>, minLoaderDuration = 400): Observable<boolean> {
  const showLoadingIndicator = source.pipe(take(1), switchMap((val) => {
    if (val) {
      return of(false, false)
    } else {
      const offTimer = timer(minLoaderDuration).pipe(map(() => false))
      return concat(of(true), combineLatest([source.pipe(filter((value) => value)), offTimer]).pipe(map(() => false)))
    }
  }), take(2))
  return defer(() => showLoadingIndicator)
}

export function minDelayPiped<T> (delayTime = 400) : (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) => {
    const startTime = Date.now()
    return combineLatest([timer(delayTime), source]).pipe(
      catchError((error: unknown) => {
        const now = Date.now()
        return timer(Math.max(delayTime - (now - startTime), 0)).pipe(
          switchMap(() => {
            return throwError(() => error)
          })
        )
      }),
      map((val: [0, T]) => val[1]))
  }
}

export function minDelay<T> (source: Promise<T>, delayTime = 400): Promise<T> {
  return new Promise((resolve, reject) => {
    const startTime = Date.now()
    const sub: Subscription = combineLatest([timer(delayTime), of(source)]).pipe(
      take(1),
      catchError((error: unknown) => {
        const now = Date.now()
        return timer(Math.max(delayTime - (now - startTime), 0)).pipe(
          switchMap(() => {
            return throwError(() => error)
          })
        )
      })
    ).subscribe({
      next: (value: [0, Promise<T>]) => {
        resolve(value[1])
        sub.unsubscribe()
      },
      error: (error) => {
        reject(error)
        sub.unsubscribe()
      }
    })
  })
}

export function generateRubyText (input: string, kanjiOnly = false): string {
  const caputringPattern = /\{([\p{L}]+?):([\p{L}]*?)\}/uig
  let replacementPattern = ''
  if (kanjiOnly) {
    replacementPattern = '$1'
  } else {
    replacementPattern = '<ruby><rb>$1</rb><rt>$2</rt></ruby>'
  }
  return input.replace(caputringPattern, replacementPattern)
}

export function tokensToRuby (input: JPTokenStream): string {
  let rubyString = ''
  console.log(input.tokens)
  input.tokens.forEach(token => {
    if (token.furigana) {
      rubyString = rubyString.concat(`<ruby>${token.base}<rt>${token.furigana}</rt></ruby>`)
    } else {
      rubyString = rubyString.concat(token.base)
    }
  })
  return rubyString
}

export function getCJKComposition (input: string): CJKComposition {
  const kanjiMatches = input.match(reKanji)
  const kanaMatches = input.match(reKana)
  if (kanjiMatches) {
    return kanaMatches ? 'mixed' : 'kanji'
  } else if (kanaMatches) {
    return 'kana'
  } else {
    return 'notCJK'
  }
}

export function charIsKanji (char: string): boolean {
  const charCode = char.charCodeAt(0)
  return (charCode >= kanjiStartCP && charCode <= kanjiEndCP) || (charCode >= kanjiextStartCP && charCode <= kanjiextEndCP)
}

export function tokenizeJPWithoutReadings (jpInput: string): Array<JPToken> {
  const tokenAry: Array<JPToken> = []
  let currentChar = ''
  for (let i = 0; i < jpInput.length; i++) {
    currentChar = jpInput.charAt(i)
    if (i === 0) {
      const token: JPToken = {
        base: currentChar
      }
      if (charIsKanji(currentChar)) {
        token.furigana = ''
      }
      tokenAry.push(token)
      continue
    }

    if (charIsKanji(currentChar)) { // kanji
      if (Object.hasOwn(tokenAry[tokenAry.length - 1], 'furigana')) { // last char is kanji
        tokenAry[tokenAry.length - 1].base = tokenAry[tokenAry.length - 1].base.concat(currentChar)
      } else { // last char is not kanji
        tokenAry.push({
          base: currentChar,
          furigana: ''
        })
      }
    } else { // not kanji
      if (Object.hasOwn(tokenAry[tokenAry.length - 1], 'furigana')) { // last char is kanji
        tokenAry.push({
          base: currentChar
        })
      } else { // last char is not kanji
        tokenAry[tokenAry.length - 1].base = tokenAry[tokenAry.length - 1].base.concat(currentChar)
      }
    }
  }
  return tokenAry
}

/**
 * Format bytes as human-readable text.
 * https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize (bytes: number, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}

export function hasCorrectSubTier (userInfo: Nullable<UserInfo>, rotueReq: RouteRequirement): Nullable<boolean> {
  if (userInfo === null) return null
  if (!rotueReq.subscription_required) return true
  if (rotueReq.subscription_required === 'tier_0') return true
  if (rotueReq.subscription_required === 'tier_1') {
    return userInfo.subscription === 'tier_1' || userInfo.subscription === 'tier_2'
  }
  if (rotueReq.subscription_required === 'tier_2') {
    return userInfo.subscription === 'tier_2'
  }
  return false
}

function dec2hex (dec: number): string {
  return dec.toString(16).padStart(2, '0')
}

// generateId :: Integer -> String
export function generateId (len = 20): string {
  const arr = new Uint8Array(len / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

export function getRandomInt (min: number, max: number): number {
  const minCeiled = Math.ceil(min)
  const maxFloored = Math.floor(max)
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled) // The maximum is exclusive and the minimum is inclusive
}

export function generateTentId (): number {
  return getRandomInt(0, 1000000)
}

export function makeDeviceId (): string {
  return `${generateId(24)}`
}

export function normalizeText (text: string, caseFold = false): string {
  text = text.trim()
  let normalizedText = text.normalize('NFKC')
  if (caseFold) {
    normalizedText = normalizedText.toLowerCase()
  }
  return normalizedText
}

export function removeNonAlphaNum (text: string): string {
  return text.replace(/[^\w\d]/, '')
}

export function convertToKanji (text: string): string {
  return text.replace(/\{([\w\d]*):([\w\d]*)\}/, '$1')
}

export function convertToFurigana (text: string): string {
  return text.replace(/\{([\w\d]*):([\w\d]*)\}/, '$2')
}

export function makeSearchableText (text: string): string {
  return removeNonAlphaNum(normalizeText(text, true))
}

export function makeSearchableTextKanjiOnly (text: string): string {
  return convertToKanji(removeNonAlphaNum(normalizeText(text, true)))
}

export function makeSearchableTextKanaOnly (text: string): string {
  return convertToFurigana(removeNonAlphaNum(normalizeText(text, true)))
}

export function copyObject<T> (val: T): T {
  return JSON.parse(JSON.stringify(val)) as T
}

// if the timestamp came from PHP or MYSQL then it is in secods, but JS timestamps are in ms
export function convertTimestamp (indexedTimestamp: number): number {
  return indexedTimestamp * 1000
}

export function replaceStringSeps (rawString: string, replacementChar: string): string {
  return rawString.replaceAll(USER_INPUT_SEP_CHAR, replacementChar)
}

/*
export function generateTentIdSafe (): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    let daNum = 0
    of(daNum).pipe(mergeMap(val => {
      daNum = generateTentId()
      console.log(daNum)
      if (daNum < 6) {
        return throwError(() => 'Error!')
      }
      return of(daNum)
    }), retry()).subscribe({
      next: (val) => {
        console.log('complete')
        console.log(val)
        resolve(val)
      },
      error: (err) => {
        console.log(err)
        reject(err)
      }
    })
  })
}
  */

/*
  if (tableType === 'vocabulary') {
    // TODO test retry
    of(generateTentId()).pipe()

/*
export function getRouteRequirement (routeLoc: RouteLocationNormalizedLoaded): Nullable<RouteRequirement> {
  let daRouteReq: Nullable<RouteRequirement> = null
  const routeName = routeLoc.name
  let daRoute: Nullable<RouteLocationMatched | RouteRecordRaw> = null
  if (routeName) {
    routeLoc.matched.forEach((routeLocMatch: RouteLocationMatched) => {
      if (routeLocMatch.name) {
        daRoute = getMatchedRoute(routeName.toString(), routeLocMatch)
      }
    })
  }
  if (daRoute && (daRoute as RouteRecordRaw)) {
    console.log('found da route!')
    console.log(daRoute)
    const daRouteRecord: RouteRecordRaw = daRoute as RouteRecordRaw
    if (daRouteRecord.props) {
      if (daRouteRecord.props as Record<string, any>) {
        const props = daRouteRecord.props as Record<string, any>
        if (props.default.routeRequirement && props.default.routeRequirement as RouteRequirement) {
          daRouteReq = props.default.routeRequirement as RouteRequirement
        }
      }
    }
  }
  return daRouteReq
}

function getMatchedRoute (routeName: string, rootRoute: RouteLocationMatched | RouteRecordRaw): Nullable<RouteLocationMatched | RouteRecordRaw> {
  if (rootRoute.name === routeName) {
    return rootRoute
  } else {
    let childRouteMatch: Nullable<RouteLocationMatched | RouteRecordRaw> = null
    if (rootRoute.children) {
      rootRoute.children.forEach((childRoute: RouteRecordRaw) => {
        childRouteMatch = getMatchedRoute(routeName, childRoute)
        if (childRouteMatch !== null) return childRouteMatch
      })
    }
  }
  return null
}
*/
