// This is just an example,
// so you can safely delete all default props below

export default {
  error: {
    failed: 'Failed to save settings.'
  },
  sync: {
    complete: 'Settings have been synced.'
  }
}
