// import { Preferences } from 'capacitor/@capacitor/preferences'
import { STORAGE_KEYS } from '@/enums'

// https://developer.mozilla.org/en-US/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria#when_is_data_evicted

export class StorageService {
  public storeValue (storageKey: STORAGE_KEYS, value: string): void {
    try {
      window.localStorage.setItem(storageKey, value)
    } catch (error) {
      console.log(error)
    }
  }

  public getValue (storageKey: STORAGE_KEYS): string | null {
    return window.localStorage.getItem(storageKey)
  }

  public deleteValue (storageKey: STORAGE_KEYS): void {
    window.localStorage.removeItem(storageKey)
  }
}
